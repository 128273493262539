@use 'src/assets/styles/variables/colors';

.containerBlockIcon {
  width: 6rem;
  height: 6rem;
  background: colors.$white;
  border: 1px solid colors.$white;
  border-radius: 50%;
  margin-left: -2.5rem;
}

.containerBlockIcon:first-child {
  margin-left: 0;
}

.containerIcons {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.user {
  display: flex;
  align-items: center;
  &__name {
    display: inline-block;
    margin-left: 2rem;
  }

  &-select {
    border: none;
  }
}

.checkbox-icon {
  min-height: 6rem;
  min-width: 6rem;
}

.row {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  grid-gap: 2rem;
  color: initial;
  &-inner {
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    max-width: calc(100% - 8rem);
  }
}
.counter {
  color: colors.$blue-70;
}
.icon {
  width: 24px;
  height: 24px;
}
