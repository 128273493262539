@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.wrapper{
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  height: 100%;
}

.title{
  display: flex;
  align-items: center;
  column-gap: 3rem;
  min-height: 40px !important;
  &-content{
    display: flex;
    align-items: center;
    column-gap: 4rem;
    &-link{
      display: flex;
      column-gap: 1rem;
      color: colors.$blue-70;
      @include text-style.ui-14;
    }
  }
}

.children{
  height: 100%;
}
