@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/shadows.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  width: 75%;
  border-radius: 2rem 0 0 2rem;
  height: 100%;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.03);
  &.nonSelected{
    border-radius: 2rem !important;
    width: 100%;
  }
}

.container {
  display: flex;
  gap: 16px;
  height: 100%;
}


.content {
  display: grid;
  grid-row-gap: 6rem;
  grid-column-gap: 4rem;
  height: 100%;

  &.providers {
    max-width: 231.75rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: max-content;
    @media(max-width: 1279px){
      max-width: 614px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    row-gap: 8rem;
    height: 100%;
    overflow-y: auto;
  }
  &-manual{
    min-height: 262px;
    max-height: 262px;
    width: 100%;
    border-radius: 2rem;
    background: colors.$blue-30;
    position: relative;
    overflow: hidden;
    &-block{
      &-blue{
        width: 417.294px;
        height: 1291.498px;
        transform: rotate(51.615deg);
        border-radius: 137px;
        background: linear-gradient(180deg, colors.$blue-60 0%, colors.$blue-90 100%);
        position: absolute;
        top: -143rem;
        left: 47rem;
      }
      &-white{
        width: 887px;
        height: 440px;
        border-radius: 887px;
        background: colors.$white;
        filter: blur(145px);
        bottom: 101px;
        position: absolute;
        left: 697px;
        z-index: 1;
      }
    }
    &-cloud{
      &-left{
        top:208px;
        left: 391px;
        width: 185px;
        height: 185px;
        position: absolute;
        border-radius: 185px;
        background: colors.$white;
      }
      &-right{
        position: absolute;
        width: 123px;
        height: 123px;
        border-radius: 123px;
        background: colors.$white;
        top: 219px;
        left: 739px;
      }
      &-center{
        width: 245px;
        height: 245px;
        border-radius: 245px;
        background: colors.$white;
        position: absolute;
        top: 148px;
        left: 494px;
      }
      &-centerRight{
        position: absolute;
        width: 123px;
        height: 123px;
        border-radius: 123px;
        background: colors.$white;
        top: 171px;
        left: 691px;
      }
    }
    &-astro{
      width: 245px;
      height: 242px;
      position: absolute;
      top: 20px;
      left: 524px;
    }
    &-svg{
      &-small{
        position: absolute;
        width: 82.253px;
        height: 45.291px;
        top: 138px;
        left: 1144px;
      }
      &-left{
        width: 221.665px;
        height: 121.794px;
        top: 30px;
        left:780px;
        position: absolute;
      }
      &-right{
        position: absolute;
        width: 219.747px;
        height: 121px;
        top: 53px;
        left: 1246px;
      }
    }
    &-welcome{
      margin: 65px 0 60px 111px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 1;
      position: inherit;
      &-text{
        color: colors.$white;
        width: 380px;
        @include text-style.heading-24;
        font-weight:700;
        margin-bottom: 32px;
      }
      &-button{
        & > div {
          background: colors.$white;
          width: 100%;
        }
        &-img{
          color: colors.$blue-70;
        }
      }
    }
  }
}

.button {
  & > div {
    padding: 0 !important;
  }
}

.contentTitle {
  @include text-style.heading-18;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid colors.$light-gray-30;
}

.headerTopContent {
  display: flex;
  padding: 6rem 4rem;
  align-items: center;
  justify-content: space-between;
  &.selected{
    padding: 6rem 4rem 4rem 4rem;
  }
}

.headerTitle {
  @include text-style.heading-18;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.iconContainer {
  width: 12rem;
  height: 12rem;
  flex-shrink: 0;
}

.stepper {
  display: flex;
  padding: 6rem 6rem;
  align-items: center;
  gap: 1.5rem;
  @media(max-width: 1439px){
    padding: 6rem 6rem 10rem 6rem;
}
}

.texts {
  display: flex;
  flex-direction: column;
}

.version {
  @include text-style.ui-12;
  color: colors.$gray-70;
}

.providers-block{
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.headerBackTitle{
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.headerEditTitle{
  @include text-style.ui-12;
  color: colors.$gray-100;
  margin-left: 32px;
  font-weight: 400;
}
