@use 'src/assets/styles/variables/colors';

.multi-user-select {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  .counter {
    color: colors.$blue-70;
  }
}
.tt {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;
  width: 220px;
  &-footer {
    display: flex;
    justify-content: flex-end;
  }
}
.user-name {
  color: colors.$white;
}
.single-user {
  font-size: 3.5rem;
}
