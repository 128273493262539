@use 'src/assets/styles/variables/layout.scss';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/common.scss';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/text-style.scss';

:global {
  .popup-profile-content {
    position: fixed !important;
    left: initial !important;
    top: 48px !important;
    right: 0 !important;
    border-top: 1px solid colors.$light-gray-10 !important;
    width: 280px;
  }

  .popup-profile-overlay{
    top: 48px !important;
  }

  .tech-popup-profile-overlay{
    top: 80px !important;
  }

  .tech-popup-profile-content{
    position: fixed !important;
    left: initial !important;
    top: 80px !important;
    right: 0 !important;
    border-top: 1px solid colors.$light-gray-10 !important;
    width: 280px;

    & > div {
      border-radius: 0 0 8px 8px;
    }
  }

  .modal-profile-content {
    position: fixed !important;
    top: 6rem !important;
    right: 6rem !important;
  }
}

.userData {
  padding: 1rem 5rem 2rem;
}

.logoutWrapper {
  padding: 0.5rem 3rem;
}

.userDataWrapper {
  cursor: pointer;

  &:hover {
    background-color: colors.$light-gray-10;
  }

  &:active {
    background-color: colors.$light-gray-30;
  }
}

.icon {
  cursor: pointer;
}

.divider {
  height: 1px;
  background-color: colors.$light-gray-30;
  margin: 3rem 0;
}

.popup {
  box-shadow: shadows.$elevation-4;
  border: 1px solid colors.$light-gray-10;
  padding: 0!important;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  background: colors.$white;
}

.modalContainer {
  position: relative;
}

.modal {
  &-inner {
    overflow: unset;
  }
}

.modalBody {
  flex-grow: 1;
}

.iconClose {
  @include common.control-icon;
  color: colors.$gray-60;
  position: absolute;
  right: 9.25rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconProfile {
  box-sizing: border-box;
  border-radius: 6px;
  width: 200px;
  height: 200px;
}

.infoUser {
  padding-top: 6rem;
  text-align: center;
}

.title {
  @include reset.indents;
  @include text-style.heading-24;
  margin-bottom: 2rem;
}

.text {
  @include reset.indents;
  @include text-style.paragraph-14;
  color: colors.$gray-90;
}

.infoContent {
  margin-top: 16rem;
}

.infoItem:not(:last-child) {
  padding-bottom: 2.75rem;
  border-bottom: 1px solid colors.$light-gray-30;
  margin-bottom: 5rem;
}

.infoItemLabel {
  @include reset.indents;
  @include text-style.ui-14;
  color: colors.$gray-90;
}

.infoItemText {
  @include reset.indents;
  @include text-style.paragraph-16;
}

.footer {
  border: none;
  display: flex;
  justify-content: space-between;
}

.icon {
  @include common.control-icon;
  color: colors.$white;
  width: 6rem;
  object-fit: cover;
}

.headerContainer {
  position: absolute;
  top: 4rem;
  right: 4rem;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.innerPopupContainer {
  position: absolute;
  top: 0;
  right: 0;
  border: none !important;
  z-index: 2;
}

.success {
  color: colors.$green-60;
  display: inline-block;
}

.error {
  color: colors.$red-60;
  display: inline-block;
}

.innerPopupText {
  @include text-style.ui-14-semi;
}

.avatarIcon {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  cursor: pointer;
}

.bodyContauner {
  height: 100%;
}
