.loaderContainer {
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  justify-content: center;
  width: 100%;

  & > div {
    height: 40px;
    border-radius: 5px;
  }
}
