@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';

.heading {
  @include reset.indents;
}
.xl {
  @include text-style.heading-36;
}
.lg {
  @include text-style.heading-24;
}
.md {
  @include text-style.heading-18;
}
.sm {
  @include text-style.heading-14;
}
.xs {
  @include text-style.heading-12;
}
