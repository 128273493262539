@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.modalContainer {
  color: colors.$dark-gray-150;
  max-width: 215rem;
}

.connectionBlock {
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.appendButtonText {
  color: colors.$blue-70;
}

.blockTitle {
  @include text-style.heading-18;
  margin-bottom: 4rem;

  &.withBorder {
    border-bottom: 1px solid colors.$light-gray-30;
    padding-bottom: 4rem;
  }
}

.info {
  margin-bottom: 4rem;
}

.blockContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.btns {
  display: flex;
  gap: 4rem;
}

.btn {
  width: 50%;
}

.deleteIcon {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;

  &:hover {
    opacity: 0.7;
  }
}

.divider {
  width: 100%;
  height: 1px;
  min-height: 1px;
  margin: 0 3rem;
  background: colors.$light-gray-30;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 4rem;
}

.items {
  display: grid;
  width: 100%;
  margin-bottom: 6rem;
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  margin-top: 6rem;
}

.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.addButton {
  width: fit-content;
}

.plusIcon {
  transform: rotate(45deg);
}

.blockContentSelect {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 4rem;

  .btn {
    margin-top: 7rem;
    width: 100%;
  }
}

.tab {
  &-list {
    position: relative;
    gap: 10rem;
    box-shadow: inset 0 -1px colors.$light-gray-40;
    button {
      display: flex;
      justify-content: center;
    }
  }
  &-content {
    padding-top: 2rem;
    height: 100%;
    min-height: 200px;

    :global {
      .rc-table {
        .rc-table-row-level-0:nth-child(even) {
          td {
            background-color: colors.$white;
          }
        }
      }
      .rc-table-header {
        top: -24px !important;
      }

      .rc-table-expanded-row {
        .rc-table-cell {
          box-shadow: none;
        }
      }

      .rc-table-expand-icon-col {
        width: 18px;
      }

      .rc-table-row-expand-icon-cell {
        padding-top: 2.5rem !important;
      }

      .rc-table-expanded-row {
        td {
          padding: 0 !important;
        }
      }
    }
  }
  &-fake {
    flex: 1 1 50%;
    border-bottom: 1px solid colors.$light-gray-40;
  }
}

.inputs{
  height: 100%;
}

.selectSubscription{
  div[id$="-placeholder"]{
    margin-left: 0;
    margin-right: 0;
    @media(max-width: 1644px){
      font-size: 12px !important;
    }
    @media(max-width: 1374px){
      font-size: 10px !important;
    }
    @media (min-width: 768px) and (max-width: 1224px) {
      font-size: 8px !important;
    }
  }
}
