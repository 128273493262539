@use '/src/assets/styles/variables/colors';

.plug {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6rem;
  background-color: colors.$white;
  z-index: 1;
}

.text {
  &-wrapper {
    text-align: center;
  }
}

.cloud {
  .image {
    width: 103px;
  }
}
.satellite {
  .image {
    width: 81px;
  }
}
.spin {
  .image {
    width: 89px;
  }
}
.constellation {
  .image {
    width: 208px;
  }
}
.constellationSmall {
  .image {
    width: 168px;
  }
}
.rocket {
  .image {
    width: 155px;
  }
}
.spaceShip {
  .image {
    width: 242px;
  }
}

.satellite,
.spaceShip {
  .image {
    opacity: 0;
    animation: fly 0.8s linear forwards 0.5s;
  }
}
.rocket {
  .image {
    opacity: 0;
    animation: fly-left 0.8s linear forwards 0.5s, fly-infinity 2s linear infinite 0.5s;
  }
}

.spin {
  .image {
    animation: rotate 3s linear infinite;
  }
}

.constellation {
  .image {
    transform: scale(1);
    animation: scale 5s linear 0.4s infinite;
  }
}

.isStatic {
  .image {
    opacity: 0;
    animation: opacity 0.8s linear forwards 0.5s;
  }
}
@keyframes fly-infinity {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fly {
  0% {
    opacity: 0;
    transform: translate(15%, 25%);
  }
  25% {
    opacity: 0.5;
    transform: translate(5%, 10%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fly-left {
  0% {
    opacity: 0;
    transform: translate(-15%, 25%);
  }
  25% {
    opacity: 0.5;
    transform: translate(-5%, 10%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
