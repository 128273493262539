.toastContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.md {
    padding: 2.5rem 4rem;
    border-radius: 4px;
  }
  &.lg {
    padding: 4rem;
    border-radius: 8px;
  }

  &Text {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
