.container {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  width: 100%;
}

.checkbox-label {
  .cb {
    order: 1;
  }
  .label {
    order: 2;
  }
}

.label-checkbox {
  justify-content: space-between;
  .cb {
    order: 2;
  }
  .label {
    order: 1;
  }
}
