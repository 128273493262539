@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.header {
  font-size: 4rem;
  padding: 2rem 6rem;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-btn {
    display: inline-block;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    &__year {
      @include text-style.ui-12-semi();
      color: colors.$blue-70;
      &:hover {
        border-bottom-color: colors.$blue-70;
      }
    }
    &__month {
      @include text-style.ui-14-semi();
      &:hover {
        border-bottom-color: colors.$black;
      }
    }
  }
}

.button {
  border-radius: 50%;
  overflow: hidden;
  &_prev {
    transform: rotate(90deg);
  }
  &_next {
    transform: rotate(-90deg);
  }
}
