@use 'src/assets/styles/variables/colors';

.container {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  font-size: 3rem;
  color: colors.$blue-70;
  width: 100%;
  cursor: pointer;

  &.right {
    justify-content: flex-end;
  }
}

.left {
  .cb {
    order: 1;
  }
  .label {
    order: 2
  }
}
.right {
  .cb {
    order: 2;
  }
  .label {
    order: 1
  }
}
