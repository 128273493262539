@use '/src/assets/styles/variables/colors';

.icon {
  cursor: pointer;
  display: block;
  color: colors.$gray-70;
  transition: color 300ms ease;

  &:hover,
  &:active {
    color: colors.$dark-gray-150;
  }
}

.popup {
  border: 1px solid colors.$light-gray-10;
  margin: -1px;
}

:global {
  .menu-cell-content {
    transform: translateX(30%);
  }
}
