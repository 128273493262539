@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/colors';

@mixin checkboxWidth($width, $margin: 2rem) {
  width: $width;
  height: $width;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2.5rem;
  user-select: none;
  &.sm {
    gap: 2rem;
  }

  &:hover {
    .checkbox {
      background-color: colors.$light-gray-20;
      border-color: colors.$gray-70;
    }
    .checkbox.checked {
      background-color: colors.$blue-70;
      border-color: colors.$blue-70;
    }
    .checkbox.isError {
      border-color: colors.$red-60;
      --border-width: 2px;
    }
  }

  &:active {
    .checkbox {
      background-color: colors.$light-gray-30;
      border-color: colors.$gray-80;
    }
    .checkbox.checked {
      background-color: colors.$blue-80;
      border-color: colors.$blue-80;
    }
    .checkbox.isError {
      border-color: colors.$red-60;
      --border-width: 2px;
    }
  }

  &:-visible {
    .checkbox {
      outline: 3px solid colors.$blue-40;
    }
  }

  &.disabled {
    pointer-events: none;
    .textContainer {
      opacity: 0.5;
    }
    .checkbox {
      border-color: colors.$light-gray-50;
      &.checked {
        background-color: colors.$light-gray-50;
        border-color: colors.$light-gray-50;
      }
    }
  }
}

.containerReverse {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.input {
  display: none;
}

.checkbox {
  display: flex;
  --border-width: 1px;
  background-color: colors.$light-gray-10;
  border-radius: 4px;
  color: colors.$white;
  flex-shrink: 0;
  border: var(--border-width) solid colors.$gray-60;
  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: calc(-1 * var(--border-width));
    border-collapse: collapse;
    opacity: 0;
  }

  &.lg {
    @include checkboxWidth(5rem);
  }

  &.md {
    @include checkboxWidth(4rem);
  }

  &.sm {
    @include checkboxWidth(3rem);
    border-radius: 2px;
  }

  &.checked {
    background-color: colors.$blue-60;
    border-color: colors.$blue-60;
    .checkmark {
      opacity: 1;
    }
  }

  &.isError {
    border-color: colors.$red-60;
    --border-width: 2px;
  }
}

.textContainer {
  flex-grow: 1;
  min-width: 0;

  .caption {
    @include common.text-no-wrap;
    @include text-style.ui-14;
    color: colors.$dark-gray-140;
    margin: 0;
  }

  .error {
    color: colors.$red-70;
    @include text-style.ui-12;
    margin: 0;
  }

  &.lg {
    padding: 1rem 0;
  }
  &.sm {
    .caption {
      @include text-style.ui-12;
    }
  }
}
