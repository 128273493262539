@use './colors';
@use './text-style';

@mixin control-icon {
  cursor: pointer;
  color: colors.$gray-60;
  transition: color 0.2s;

  &:hover {
    color: colors.$dark-gray-150;
  }
}

@mixin text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin filed-error-message {
  @include text-style.ui-12;
  color: colors.$red-70;
}

@mixin modal-enter-animation {
  @keyframes modal-enter {
    0% {
      opacity: 0;
      transform: translate(100rem);
    }
    50% {
      opacity: 0.5;
      transform: translate(50rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  animation: modal-enter 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  transform: translate(100rem);
}

@mixin spin-animation {
  @keyframes spin {
    from {
     transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 4s linear infinite;
}
