@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.footer {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.separator {
  width: 100%;
  height: 1px;
  background: colors.$light-gray-30;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.avatar {
  margin: 0 auto;
}

.nameBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.name {
  @include text-style.heading-24;
  color: colors.$dark-gray-150;
}

.roles {
  @include text-style.ui-14;
  color: colors.$gray-60
}

.informationBlock {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.datesBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem;
  gap: 4.5rem;
  border: 1px solid colors.$light-gray-30;
  border-radius: 8px;
}

.dateValueBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.dateLabel {
  @include text-style.ui-14;
  color: colors.$gray-100;
}

.commentBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.commentLabel {
  @include text-style.ui-14;
  color: colors.$gray-70;
}

.commentText {
  @include text-style.ui-14;
  color: colors.$black
}

.dateValue {
  @include text-style.ui-14;
  color: colors.$black;
}

.separator {
  margin-top: 1rem;
  width: 100%;
  border-top: 1px solid colors.$light-gray-40;
}

.leftButton {
  @include text-style.ui-14;
}

.rightButton {
  @include text-style.ui-14;
}


.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
