@use 'src/assets/styles/variables/colors';

.wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  row-gap: 8rem;
}

.text{
  display: flex;
  align-items: center;
  flex-direction: column;
  &-special{
    cursor: pointer;
    color: colors.$blue-70;
  }
}
