@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/colors';

.report {
  position: relative;
  &Inner {
    display: flex;
    flex-direction: column;
    gap: 6rem;
  }
  &Row {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    &Title {
      color: colors.$gray-70;
      @include text-style.ui-12;
    }
    &Content {
      display: flex;
      gap: 4rem;
      &Small {
        gap: 2rem;
      }
      &Col {
        flex-direction: column;
      }
      &_row {
        flex-direction: row;
      }
      &_column {
        flex-direction: column;
      }
    }
  }
}



.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 800;
}

.file {
  display: flex;
  align-items: center;
  gap: 3rem;
  cursor: pointer;

  svg {
    width: 7.5rem;
    height: 10rem;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.icon {
  color: colors.$dark-gray-150;
}

.formats {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.footer {
  display: flex;
  &_left {
    justify-content: flex-start;
  }
  &_right {
    justify-content: flex-end;
  }
}

.select {
  &Wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  &Badges {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  &Badge {
    font-weight: 400!important;
    &Icon {
      opacity: 0.3;
    }
  }
}

.popup {
  display: inline-flex;
  &-window {
    border: 1px solid colors.$light-gray-10;
    padding: 0!important;
  }
  &-wrap {
    width: 75rem;
  }
  &-content {
    padding: 4rem;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 4rem 0 4rem;
    &__icon {
      color: colors.$gray-70;
      cursor: pointer;
    }
    &__title {
      @include text-style.paragraph-16-semi();
    }
  }
}
