@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.imageContainer {
  display: flex;
  position: relative;
  width: 50rem;
  height: 50rem;
  border-radius: 6px;
  box-sizing: content-box;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}

.infoUser {
  padding-top: 6rem;
  text-align: center;
}

.title {
  @include reset.indents;
  @include text-style.heading-24;
  margin-bottom: 2rem;
}

.text {
  @include reset.indents;
  @include text-style.paragraph-14;
  color: colors.$gray-90;
}


.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
