@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style';

.card {
  padding: 1rem;
  box-shadow: shadows.$block;
  border-radius: 8px;
  background: colors.$white;
}

.text {
  @include text-style.paragraph-14;
  color: colors.$gray-90;
  margin-bottom: 2rem;
}
