@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/colors';

.container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  gap: 2.5rem;
  user-select: none;

  .text {
    @include text-style.ui-14;
    color: colors.$dark-gray-140;
  }
  .textwitherror {
    @include text-style.ui-12;
    display: flex;
    flex-direction: column;
  }
  .errortext {
    color: colors.$red-70;
  }
  .ring {
    width: 6rem;
    height: 6rem;
    position: relative;
    border-radius: 9999px;
  }
  .ringselected:focus-within {
    background-color: colors.$blue-40;
  }
  .ringinner {
    width: 100%;
    height: 100%;
    border-radius: 9999px;
  }
  .ringinnererror {
    width: 75%;
    height: 75%;
    top: 12.5%;
    left: 10%;
    border-radius: 9999px;
    border: colors.$red-70 2px solid;
    background-color: colors.$light-gray-10;
    position: absolute;
  }
  .selected:hover {
    background-color: colors.$blue-70;
  }
  .selected:active {
    background-color: colors.$blue-80;
  }
  .available {
    color:red;
  }
  .selected {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-radius: 9999px;
    position: absolute;
    background-color: colors.$blue-60;
  }
  .selected-inner {
    width: 37.5%;
    height: 37.5%;
    top: 31.25%;
    left: 31.25%;
    border-radius: 9999px;
    position: absolute;
    background-color: white;
  }
  .error {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-radius: 9999px;
    position: absolute;
    background-color: colors.$red-70;}
  .invisible {
    opacity: 0;
    pointer-events: none;
  }
  .nonselected {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-radius: 9999px;
    background-color: colors.$gray-60;
    position: absolute;
  }
  .nonselected-inner {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    border-radius: 9999px;
    position: absolute;
    background-color: colors.$light-gray-10;
  }
  .nonselected:hover {
    background-color: colors.$gray-70;
  }
  .nonselected-inner:hover {
    background-color: colors.$light-gray-20;
  }
  .nonselected:active {
    background-color: colors.$gray-80;
  }
  .nonselected-inner:active {
    background-color: colors.$light-gray-30;
  }
  &.halfinvisible {
    opacity: 50%;
    pointer-events: none;
  }
}
