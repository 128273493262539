@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style';

.dp {
  width: 360px;
  background-color: colors.$white;
  border-radius: 2rem;

  &_fix {
    height: 330px;
  }

  :global {
    .react-datepicker {
      width: 100%;
      border: none;
      &__day {
        overflow: hidden;
        width: 9rem;
        height: 9rem;
        font-size: 3rem;
        border-radius: 50%;
        margin: 0.75rem;
        @include text-style.ui-12();
        &--disabled {
          color: #ccc!important;
        }
        &--today {
          border: 1px solid colors.$blue-70!important;
          color: colors.$black;
        }
        &--selected {
          background-color: transparent;
          color: colors.$black;
        }
        &--keyboard-selected {
          background-color: colors.$blue-70;
        }
        &--outside-month {
          opacity: 0;
          pointer-events: none;
        }
        &-name {
          margin: 0.75rem;
          text-transform: capitalize;
        }
        &--selecting-range-start {
          background-color: colors.$blue-70!important;
          color: colors.$white!important;
        }
        &--range-start {
          background-color: colors.$blue-70!important;
          color: colors.$white!important;
        }
        &--selecting-range-end {
          background-color: colors.$blue-70!important;
          color: colors.$white!important;
        }
        &--range-end {
          background-color: colors.$blue-70!important;
          color: colors.$white!important;
        }
        &--in-range {
          background-color: colors.$blue-30;
          color: colors.$blue-70;
        }
        &--in-selecting-range {
          background-color: colors.$blue-30;
          color: colors.$blue-70;
        }
      }
      &__month-container {
        float: unset;
      }
      &__header {
        background-color: colors.$white;
        border: none;
        padding: 0 0 2rem 0;
      }
      &__day-name {
        @include text-style.ui-14();
        color: colors.$gray-70;
        width: 8rem;
        height: 6rem;
      }
    }
  }
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  &_selected {
    background-color: colors.$blue-70;
    color: colors.$white;
  }
}

