.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8rem;
  &_month {
    height: 100%;
  }
}

.button {
  &-month {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
  }
  &-year {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
}
