@mixin text {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  text-align: inherit;
}

@mixin indents {
  padding: 0;
  margin: 0;
}

@mixin input {
  @include text;
  @include indents;
  display: block;
  box-shadow: none;
  outline: none;
  border: none;
}

@mixin ul {
  @include indents;
  list-style: none;
}

@mixin button {
  @include text;
  @include indents;
  border: none;
  background: none;
}
