@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.manual{
  &-wrapper{
    display: flex;
    column-gap: 1rem;
    align-items: center;
    color: colors.$blue-70;
    &:hover .manual-text{
      width: 82px;
    }
  }
  &-text{
    width: 0;
    @include text-style.ui-14;
    white-space: nowrap;
    overflow: hidden;
    transition: width .3s ease-in-out;
  }
}
