@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/colors';

.wrapper {
  display: grid;
  grid-template-columns: 265px 120px 128px 62px 32px;
  gap: 16px;
  align-items: flex-start;

  [data-testid="input-label"] > div > div {
    &:first-child, &:last-child {
      padding: 3px 8px !important;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.button {
    margin-top: 19px;
  }
}

.row {
  display: flex;
  gap: 6px;
  min-height: 32px;
  align-items: flex-start;

  &.center {
    align-items: center;
  }
}

.fullPrice {
  @include text-style.ui-14-semi;
}

.caption {
  @include text-style.ui-12;
  color: colors.$gray-70;
}

.deleteIcon {
  color: colors.$red-60;
}
