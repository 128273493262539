@use 'sizes';
@use 'font-family';

@mixin heading-36 {
  font-weight: 700;
  font-size: sizes.$xxl;
  line-height: 12rem;
  letter-spacing: -0.022em;
}
@mixin heading-24 {
  font-weight: 600;
  font-size: sizes.$xl;
  line-height: 8rem;
  letter-spacing: -0.019em;
}
@mixin heading-18 {
  font-weight: 600;
  font-size: sizes.$lg;
  line-height: 6rem;
  letter-spacing: -0.014em;
}
@mixin heading-14 {
  font-weight: 600;
  font-size: sizes.$sm;
  line-height: 6rem;
  letter-spacing: 0.006em;
  text-transform: uppercase;
}
@mixin heading-12 {
  font-weight: 600;
  font-size: sizes.$xs;
  line-height: 4rem;
  letter-spacing: 0.018em;
  text-transform: uppercase;
}

@mixin paragraph-16 {
  font-size: sizes.$md;
  line-height: 6rem;
  letter-spacing: -0.011em;
}

@mixin paragraph-16-semi {
  @include paragraph-16;
  font-weight: 600;
}

@mixin paragraph-14 {
  font-size: sizes.$sm;
  line-height: 5rem;
  letter-spacing: -0.006em;
}

@mixin paragraph-14-semi {
  @include paragraph-14;
  font-weight: 600;
}

@mixin code-14 {
  font-family: font-family.$secondary;
  font-size: sizes.$sm;
  line-height: 6rem;
}

@mixin ui-14 {
  font-size: sizes.$sm;
  line-height: 6rem;
  letter-spacing: -0.006em;
}
@mixin ui-14-semi {
  @include ui-14;
  font-weight: 600;
}
@mixin ui-12 {
  font-size: sizes.$xs;
  line-height: 4rem;
}
@mixin ui-12-semi {
  @include ui-12;
  font-weight: 600;
}
@mixin ui-10 {
  font-size: sizes.$xxs;
  line-height: 3rem;
}

@mixin ui-10-semi {
  font-size: sizes.$xxs;
  line-height: 4rem;
  font-weight: 600;
}

@mixin table-number-14 {
  font-size: sizes.$sm;
  line-height: 6rem;
  letter-spacing: -0.006em;
}
