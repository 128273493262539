@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.modal {
  height: 100%;
}

.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.spin-icon {
  width: 118px;
  height: 116px;
  @include common.spin-animation();
  &-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10rem 0 0 0;
  }
  &__text {
    @include text-style.paragraph-16();
    font-weight: 600;
    color: colors.$gray-100;
    margin-top: 8rem;
  }
}

.sections {
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr 1fr;
  padding: 4rem 0;
  &Item {
    width: 100%;
    cursor: pointer;
    transition: transform .3s ease-out;
    &:hover {
      transform: translateY(-5%);
    }
    &Inner {
      padding: 4rem;
      border: 1px solid colors.$light-gray-30;
      border-radius: 2rem;
      display: inline-flex;
      gap: 4rem;
      align-items: center;
      width: 100%;
      color: colors.$dark-gray-150;
    }
    &Icon {
      color: colors.$gray-70;
    }
  }
}

.subtitle {
  @include text-style.paragraph-14;
  font-weight: 600;
  margin: 0;
}

.support {
  &Inner {
    background-color: colors.$light-gray-10;
    width: 100%;
    border-radius: 2rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  &Links {
    display: inline-flex;
    align-items: center;
    gap: 28rem;
  }
  &Link {
    display: inline-flex;
    align-items: center;
    gap: 3rem;
    color: colors.$gray-70;
    text-decoration: none;
    &Text {
      color: colors.$black;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid colors.$light-gray-40;
  padding: 0 0 4rem 0;
  &Title {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  &Wrap {
    padding:4rem 6rem  0 6rem;
    &Bordered {
      padding: 0;
      .modalHeader {
        padding: 4rem 6rem;
      }
    }
  }
}

.icon {
  @include common.control-icon;
  cursor: pointer;
  &Back {
    animation: icon-enter 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
}

@keyframes icon-enter {
  0% {
    opacity: 0;
    transform: translate(-20%) rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: translate(0) rotate(90deg);
  }
}

.about {
  padding: 2rem 0 6rem;
}

.currency {
  border: 1px solid colors.$light-gray-30;
  border-radius: 2rem;
  padding: 4rem;
  margin-bottom: 6rem;
}

.currencyHeader {
  @include text-style.paragraph-14;
  font-weight: 600;
  margin-bottom: 4rem;
  color: colors.$dark-gray-130;
}

.currenciesContainer {
  display: flex;
  flex-wrap: wrap;
}

.currencyItem {
  align-items: center;
  display: flex;
  gap: 2rem;
  padding: 0 4rem;
  border-right: 1px solid colors.$light-gray-30;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    border-right: none;
    padding-right: 0;
  }
}

.currencyText {
  @include text-style.paragraph-14;
  color: colors.$gray-90;
  margin: 0;
}

.currencySymbol {
  font-weight: 600;
  margin: 0;
}

.currencyValue {
  @include text-style.paragraph-16;
  font-weight: 600;
  margin: 0;
}
