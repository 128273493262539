@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.placeholder {
  color: colors.$gray-70;
  @include text-style.ui-14;
}

.button {
  width: 200px;
  position: relative;

  > div {
    justify-content: flex-start;
    background: colors.$white;
  }
}

.popup {
  padding: 0;
}

.iconClose {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
}

.text {
  @include text-style.ui-14-semi;
  white-space: nowrap;
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.disclaimer {
  padding: 3px 8px;
}
