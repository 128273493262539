@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/font-family';
@use '/src/assets/styles/variables/colors';

.title {
  @include reset.indents;
  @include reset.text;
  font-family: font-family.$primary;
  color: colors.$dark-gray-150;
  text-align: center;
}

.large {
  @include text-style.heading-24;
}

.small {
  @include text-style.heading-18;
}
