@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.modalContainer {
  color: colors.$dark-gray-150;
  max-width: 215rem;
}

.connectionBlock {
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.appendButtonText {
  color: colors.$blue-70;
}

.blockTitle {
  @include text-style.heading-18;
  margin-bottom: 4rem;

  &.withBorder {
    border-bottom: 1px solid colors.$light-gray-30;
    padding-bottom: 4rem;
  }
}

.info {
  margin-bottom: 4rem;
}

.blockContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
  &.three{
    &-small{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.btns {
  display: flex;
  gap: 4rem;
}

.btn {
  width: 50%;
}

.deleteIcon {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;

  &:hover {
    opacity: 0.7;
  }
}

.divider {
  width: 100%;
  height: 1px;
  min-height: 1px;
  margin: 0 3rem;
  background: colors.$light-gray-30;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 4rem;
}

.items {
  display: grid;
  width: 100%;
  margin-bottom: 6rem;
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  margin-top: 6rem;
}

.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.addButton {
  width: fit-content;
}

.plusIcon {
  transform: rotate(45deg);
}
