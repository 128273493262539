@use 'src/assets/styles/variables/common.scss';
@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/reset.scss';

:global {
  .popup-notifications-content {
    position: fixed !important;
    top: 10rem !important;
  }

  .side-modal-content {
    padding: 0;
  }
}

.modalWrap,
.modalInner {
  border-radius: 0 !important;
  animation: none;
  transform: translate(0);
}

.popupContainer {
  line-height: 0;
}

.container {
  flex: 1 1;
  height: 100%;
}


.popupHeader {
  border-bottom: 1px solid colors.$light-gray-40;
  padding: 4rem 6rem;
}

.title {
  @include reset.indents;
  @include text-style.heading-18;
}

.icon {
  @include common.control-icon;
  color: colors.$gray-60;
}

.headerContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.controllersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &Hidden {
    display: none;
  }
}


.notification {
  &-icon {
    color: colors.$gray-70;
    transition: color .25s ease-in-out;
    cursor: pointer;

    &:hover {
      color: colors.$gray-100;
    }
  }

  &-popup {
    cursor: pointer;
    padding: 2rem 3rem;
    transition: background-color .25s ease-in-out;

    &:hover {
      background-color: colors.$light-gray-10
    }
  }
}


.notificationBlock {
  display: flex;

  &.isVisibleSpinner {
    background: colors.$purple-10;
    border: 1px solid colors.$purple-20;
    border-radius: 8px;
    padding: 1rem 1.5rem 1rem 2rem;
    cursor: pointer;
  }

  & .loaderBlock {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-right: 1rem;
    margin-right: 1rem;

    &::after {
      content: '';
      position: absolute;
      width: 0.25rem;
      height: 100%;
      background: colors.$purple-20;
      right: 0;
    }
  }

  & .loader {
    color: colors.$purple-80;
  }

  & .textLoader {
    @include text-style.ui-14-semi;
    color: colors.$purple-80;
  }
}

.close {
  color: colors.$gray-60;
}

.controls {
  display: flex;
  align-items: center;
  grid-gap: 5rem;
}

.icon {
  @include common.control-icon;
}

.eye {
  color: colors.$black;
  &_read {
    color: colors.$gray-60!important;
    cursor: default;
  }
}
