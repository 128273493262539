@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: colors.$dark-gray-140;
  @include text-style.ui-14;
  margin-top: 3rem;

  .button {
    &:first-of-type {
      margin-right: 1rem;
    }
  }
  .icon {
    padding: calc(1rem - 1px) calc(2rem - 1px);
  }

  .text {
    margin-left: 2rem;
  }
}

.pageSize {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.control {
  min-width: 18rem;
}

.page {
  margin-right: 6rem;
  display: flex;
  align-items: center;
}

.totalItems {
  @include text-style.ui-14-semi;
  flex-grow: 0.5;
}

.controls {
  flex-grow: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
