@use '/src/assets/styles/variables/colors';

.warning {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  border-radius: 4px;
  background-color: colors.$light-gray-10;
  padding: 2.5rem 4rem;
  &__icon {
    color: colors.$gray-60;
  }
}
