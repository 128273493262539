@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  @include text-style.ui-14;
}

.description {
  color: colors.$gray-70;
  font-size: 3rem;
}

.bottom-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.error-text {
  color: colors.$red-70;
  font-size: 3rem;
}

.lg {
  --caption-margin: 0 0 4px 0;
}

.md {
  --caption-margin: 0 0 4px 0;
}

.sm {
  --caption-margin: 0 0 2px 0;
}

.caption {
  @include text-style.ui-14;
  transition: opacity .3s ease-in;
  margin: var(--caption-margin);
}

.disabled {
  .caption {
    opacity: 0.4;
  }
  .description {
    opacity: 0.7;
  }
}
