@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style';

:global {
  .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
    height: 0 !important;
  }

  .react-datepicker__month-container {
    border-left: 1px solid colors.$light-gray-40;
    height: 100%;
  }

  .react-datepicker__day {
    @include text-style.ui-14;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .react-datepicker__month-text--keyboard-selected, .react-datepicker__day--keyboard-selected {
    background-color: #fff !important;
    color: colors.$dark-gray-140 !important;

    &:hover {
      background-color: colors.$light-gray-10 !important;
    }

    &:active {
      background-color: colors.$light-gray-30 !important;
    }
  }

  .react-datepicker__month--selected, .react-datepicker__day--selected {
    background-color: colors.$blue-60 !important;
    color: colors.$white !important;

    &:hover {
      background-color: colors.$blue-70 !important;
    }

    &:active {
      background-color: colors.$blue-80 !important;
    }
  }

  .react-datepicker__day {
    transition: background-color .3s;

    &:hover {
      background-color: colors.$light-gray-10 !important;
    }

    &:active {
      background-color: colors.$light-gray-30 !important;
    }
  }

  .react-datepicker__month--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0;
    background-color: colors.$blue-30 !important;
    color: colors.$dark-gray-140 !important;

    &:hover {
      background-color: colors.$blue-40 !important;
    }

    &:active {
      background-color: colors.$blue-50 !important;
    }
  }

  .react-datepicker__month--range-end, .react-datepicker__month--selecting-range-end, .react-datepicker__day--selecting-range-end, .react-datepicker__day--range-end {
    border-radius: 0 6px 6px 0;
    background-color: colors.$blue-60 !important;
    color: colors.$white !important;

    &:hover {
      background-color: colors.$blue-70 !important;
      border-radius: 0 6px 6px 0;
    }

    &:active {
      background-color: colors.$blue-80 !important;
      border-radius: 0 6px 6px 0;
    }
  }

  .react-datepicker__day--selected {
    border-radius: 6px;

    &:hover {
      border-radius: 6px;
      background-color: colors.$blue-70!important;
    }

    &:active {
      border-radius: 6px;
      background-color: colors.$blue-80 !important;
    }
  }

  .react-datepicker__month--range-start, .react-datepicker__month--selecting-range-start, .react-datepicker__day--selecting-range-start, .react-datepicker__day--range-start {
    border-radius: 6px 0 0 6px;
    background-color: colors.$blue-60 !important;
    color: colors.$white !important;

    &:hover {
      background-color: colors.$blue-70 !important;
      border-radius: 6px 0 0 6px;
    }

    &:active {
      background-color: colors.$blue-80 !important;
      border-radius: 6px 0 0 6px;
    }
  }

  .react-datepicker__month--disabled {
    border-radius: 6px 0 0 6px;
    background-color: colors.$white !important;
    color: colors.$dark-gray-150 !important;
    opacity: 0.45;

    &:hover {
      background-color: colors.$white !important;
      border-radius: 6px 0 0 6px;
    }

    &:active {
      background-color: colors.$white !important;
      border-radius: 6px 0 0 6px;
    }
  }

  .react-datepicker__month-container {
    display: grid;
    flex-direction: column;
    grid-template-rows: max-content;
    height: 100%;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__month {
    display: grid;
    height: fit-content;
    grid-template-rows: 1fr;
    width: 100%;
    margin: 0;
  }
}
