@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.menu {
  @include text-style.ui-14;
  padding: 0;
  list-style-type: none;
  margin: 0;
  transition: background-color 300ms ease;
}

.menuItem {
  padding: 1rem 5rem;
  cursor: pointer;
  color: colors.$dark-gray-150;

  &.danger {
    color: colors.$red-70;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.active {
    background-color: colors.$light-gray-30;
  }

  &:hover {
    background-color: colors.$light-gray-10;
  }

  &:active {
    background-color: colors.$light-gray-30;
  }
}
