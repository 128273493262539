@use 'src/assets/styles/variables/colors';

.headerContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid colors.$light-gray-30;
  padding-bottom: 4rem;
  margin: 6rem 6rem 0 6rem;
}
