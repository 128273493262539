@use '/src/assets/styles/variables/sizes';

.icon {
  display: flex;

  &_xs {
    width: sizes.$xs;
    height: sizes.$xs;
  }

  &_sm {
    width: sizes.$sm;
    height: sizes.$sm;
  }

  &_md {
    width: sizes.$md;
    height: sizes.$md;
  }

  &_lg {
    width: sizes.$lg;
    height: sizes.$lg;
  }

  &_xl {
    width: sizes.$xl;
    height: sizes.$xl;
  }

  &_xxl {
    width: sizes.$xxl;
    height: sizes.$xxl;
  }

  &_xxxl {
    width: sizes.$xxxl;
    height: sizes.$xxxl;
  }
}
