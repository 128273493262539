@use '/src/assets/styles/variables/colors';

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;
}
