.wrapper {
  height: fit-content;
  padding-right: 5px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  width: 100%;
  height: fit-content;
}
