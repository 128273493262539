@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/common';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid colors.$light-gray-30;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include text-style.heading-18;
  color: colors.$dark-gray-150;
}

.closeIcon {
  @include common.control-icon;
}

.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbsSection {
  display: flex;
  align-items: center;
  gap: 6px;
  color: colors.$gray-60;

  &.isLast {
    color: colors.$dark-gray-140;
  }
}

.sectionContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.breadcrumbsIcon {
  color: colors.$gray-60;
}

.arrowIcon {
  transform: rotate(-90deg);
}
