@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

:global {
  .rc-table-cell {
    .roleImage {
      width: 16px;
      height: 16px;
      margin-right: 1rem;

      path {
        fill: colors.$gray-60;
      }
    }
  }

  .rc-table-cell-row-hover {
    .roleImage {
      &.clouder {
        path {
          fill: rgb(196, 121, 243);
        }
      }

      &.coster {
        path {
          fill: #38D9B9;
        }
      }
    }
  }

  .rc-table-row-active {
    .roleImage {
      &.clouder {
        path {
          fill: rgb(196, 121, 243);
        }
      }

      &.coster {
        path {
          fill: #38D9B9;
        }
      }
    }
  }
}

.container {
  display: grid;
  grid-template-areas: "avatar name"
                       "avatar roles";
  grid-template-columns: max-content;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;

  .avatar {
    grid-area: avatar;
  }

  .name {
    @include text-style.ui-14;
    grid-area: name;
  }

  .role {
    display: flex;
    align-items: center;
    @include text-style.ui-12;
    color: colors.$gray-80;
  }

  .roles {
    display: grid;
    grid-template-columns: max-content;
    grid-auto-flow: column;
    grid-column-gap: 2rem;
    grid-area: roles;
  }
}
