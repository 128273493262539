@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/shadows.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapperCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
}

.close {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include text-style.heading-18;
  margin-right: 2rem;
}

.form {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
}

.rowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.full {
  grid-template-columns: 1fr;
}

.fieldArrayBlock{
  background: colors.$light-gray-10;
  border-radius: 4px;
  width: 100%;
  padding: 2rem 4rem;

  &Title {
    @include text-style.paragraph-14;
    margin: 0;
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &Cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
    overflow: hidden;
  }

  .settings {
    &Menu {
      border-radius: 2rem 0 0 2rem;
      padding: 4rem;
      height: 100%;
      min-height: 100%;
      max-width: 520px;
      gap: 2rem;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &Content {
      border-radius: 0 2rem 2rem 0;
      height: 100%;
      min-height: 100%;
      flex: 1;
    }
  }
}
