@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper{
  background-color: colors.$white;
  border-radius: 8px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.03);
  &-language{
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
  }
  &-content{
    padding: 6rem;
    display: flex;
    flex-direction: column;
    row-gap: 8rem;
    max-width: 329px;
  }
  &-currency{
    max-width: 329px;
  }
}

.language{
  &-label{
    @include text-style.heading-14;
    color: colors.$dark-gray-120;
  }
  &-content{
    &-icon{
      width: 5rem;
      height: 5rem;
      display: flex;
    }
    &-button{
      width: 165px;
    }
  }
}

.currency{
  &-label{
    @include text-style.heading-14;
    color: colors.$dark-gray-120;
    margin-bottom: 2rem;
  }
}

.active{
  font-weight: 600;
}

.button {
  display: flex;
  cursor: pointer;
  @include text-style.ui-14;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 1.5rem;
  color: colors.$dark-gray-140;
  margin-left: auto !important;

  &:hover {
    background-color: colors.$light-gray-10;
  }
}

.buttonInGroup {
  width: 100%;
  &:focus {
    outline: none !important;
  }
}

.buttonGroup {
  min-height: 10rem;
  margin-bottom: 4rem;
}

.hr {
  height: 1px;
  width: 100%;
  position: relative;
  background-color: colors.$light-gray-40;
}

.tabList {
  display: flex;
  gap: 2rem;
  margin-bottom: 5rem;
}

.tab {
  width: calc(50% - 1rem);
}

.tabPanelContent {
  min-height: 33rem;
}
