@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';

.loaderBlock {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 1rem;
  margin-right: 1rem;

  &::after {
    content: '';
    position: absolute;
    width: 0.25rem;
    height: 100%;
    background: colors.$purple-20;
    right: 0;
  }
}

.loader {
  color: colors.$purple-80;
}

.textLoader {
  @include text-style.ui-14-semi;
  color: colors.$purple-80;
}
