@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

.counter {
  position: relative;
}

.counterValue {
  @include text-style.ui-12-semi;
  color: colors.$red-20;
  padding: 0 1rem;
  height: 5rem;
  background-color: colors.$red-60;
  border: 2px solid colors.$white;
  position: absolute;
  text-align: center;

  &__Min {
    top: 0;
    right: 0;
    border-radius: 16px;
    transform: translate(30%, -30%);
    width: 5rem;
    min-width: fit-content;
  }

  &__Max {
    top: 0;
    right: 0;
    width: fit-content;
    border-radius: 16px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
}
