@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style';

.operations {
  min-height: 14rem;
  position: absolute;
  left: -3rem;
  right: -3rem;
  top: -4rem;
  background-color: colors.$white;
  padding: 3rem 4rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: shadows.$block;
  display: flex;
  align-items: center;
}

.operationItem {
  @include text-style.ui-14;
  color: colors.$dark-gray-140;

  &.right {
    margin-left: auto;
    & ~ .right {
      margin: 0;
    }
  }

  .danger {
    color: colors.$red-70;
  }
}
