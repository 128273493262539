@use '/src/assets/styles/variables/colors';

.emails {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  &-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    &__icon {
      opacity: 0.3;
    }
    &__text {
      font-weight: 400!important;
    }
  }
}

.close {
  &__icon {
    color: colors.$gray-60;
    cursor: pointer;
  }
}
