@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.miniCard {
  padding: 4rem;
  display: flex;
  align-items: center;
  @include text-style.paragraph-16;
  border: 1px solid colors.$gray-60;
  border-radius: 8px;
  color: colors.$dark-gray-150;
  transition: border 300ms ease;
  cursor: pointer;
  gap: 4rem;

  &.selected {
    border-color: colors.$blue-70;
    background-color: colors.$blue-20;
  }
  &:hover {
    background-color: colors.$blue-10;
  }
  &.disabled {
    pointer-events: none;
    border-color: colors.$light-gray-30;
    color: colors.$gray-60;
  }
}
