@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';

.loadingPageContainer {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: colors.$light-gray-10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: colors.$dark-gray-130;
  top: 0;
}

.loadingText {
  @include text-style.heading-24;
  text-align: center;
  margin-bottom: 8rem;

  @media (max-width: 500px) {
    @include text-style.heading-18;
  }
}

.contentContainer {
  width: 97rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    width: 69rem;
  }
}

.mobileBig {
  margin-bottom: 8rem;
  width: 29.75rem;
  height: 37rem;

  @media (max-width: 500px) {
    display: none;
  }
}

.mobileSmall {
  display: none;
  margin-bottom: 10rem;
  width: 11.25rem;
  height: 16.75rem;

  @media (max-width: 500px) {
    display: block;
  }
}
