@use '/src/assets/styles/variables/colors';

.modules {
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-flex;
  gap: 6rem;
  &-container {
  }
  &-list {
    padding: 4rem 0;
    margin: 0;
    display: flex;
    gap: 6rem;
    &__system {
      list-style: none;
      background: colors.$white;
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.03);
      border-radius: 2rem;
      padding: 5rem 4rem 5rem 9.5rem;
      display: flex;
      align-items: center;
      height: 100%;
      &_demo {
        transform: translateY(0);
        transition: transform .3s ease-out;
        cursor: pointer;
        &:hover {
          transform: translateY(-5%);
        }
      }
      &-animated {
        flex-basis: 33.3%;
      }
    }
  }
}

.system {
  display: flex;
  align-items: center;
  gap: 11rem;
  &__img {
    width: 20rem;
    height: 20rem;
    min-width: 14rem;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &-label {
      height: 5rem;
    }
    &-desc {
      color: colors.$gray-90;
    }
  }
}
