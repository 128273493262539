@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/sizes';

.avatar {
  &_md {
    min-width: sizes.$md;
    width: sizes.$md;
    height: sizes.$md;
  }

  &_lg {
    min-width: sizes.$lg;
    width: sizes.$lg;
    height: sizes.$lg;
  }

  &_xl {
    min-width: sizes.$xl;
    width: sizes.$xl;
    height: sizes.$xl;
  }
}

.text {
  @include text-style.ui-10-semi;

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 1.5rem;
}
