@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/shadows';

.container {
  position: fixed;
  top: 10px;
  left: 30px;
  z-index: 1000;
  opacity: 0;

  &.noPortal {
    position: absolute;
    left: 0;
    top: 35px;
    width: 100%;
  }
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 3rem 3rem 2rem;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.inner {
  border: 1px solid colors.$light-gray-20;
  box-shadow: shadows.$block;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background: colors.$white;
}

.top {
  padding-top: 4rem;
}

.bottom {
  background: colors.$light-gray-10;
  border-top: 1px solid colors.$light-gray-30;
  padding: 3rem 20px;
  color: colors.$gray-80;
  margin-top: 12px;
}
