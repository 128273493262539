.form {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
}

.full {
  grid-column: 1 / 3;
}

.radio {
  min-height: 9.5rem;
  display: grid;
  grid-gap: 3.5rem;
  &-group {
    display: grid;
    grid-template-columns: repeat(2,max-content);
    grid-gap: 3rem;
  }
  &-groupItem {
    display: grid;
    grid-template-columns: repeat(2,max-content);
    grid-gap: 2rem;
  }
}
