@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;

  :global {
    th > div {
      justify-content: center;

      & > div {
        @include text-style.ui-14-semi;
        color: colors.$dark-gray-110;
      }
    }

    .rc-table-row > .rc-table-cell {
      &:first-child {
        border-right: 1px solid colors.$light-gray-30;
      }
    }
    tr > .rc-table-cell {
      &:first-child {
        border-right: 1px solid colors.$light-gray-30;
      }
    }

    [data-row-key="clouds.connection.vdc.head.name000000"] {
      td {
        background-color: colors.$white !important;
        color: colors.$gray-90;
      }
    }
  }
}


.tableName {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tableIcon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.title {
  @include text-style.heading-18;
}

.plug-loading{
  height: 100%;
  width: 100%;
  position: relative;
}

.subText{
  font-weight: 400 !important;
  line-height: 6rem !important;
  letter-spacing: -0.006em !important;
  font-size: 14px !important;
  color: colors.$gray-90 !important;
}

.table-wrapper{
  width: 100% !important;
}
