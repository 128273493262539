@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 6rem;
}

.detail {
  &Block {
    border-radius: 2rem;
    border: 1px solid colors.$light-gray-30;
    padding: 4rem;
  }

  &Description {
    background: colors.$light-gray-10;
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include text-style.paragraph-14;

  &Title {
    color: colors.$gray-100;
  }

  &:last-child {
    flex-direction: column;
    align-items: start;
  }
}

.userBlock {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cloudItemWrapper {
  > div {
    gap: 1rem;
  }
}

.descriptionInfo {
  background: colors.$white;
  border-radius: 1.5rem;
  padding: 4rem;
  margin-top: 1rem;
}
