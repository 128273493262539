@use 'src/assets/styles/variables/common.scss';
@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/reset.scss';

:global {
  .popup-notifications-content {
    position: fixed !important;
    top: 10rem !important;
  }
}

.modalWrap,
.modalInner {
  padding-right: 0;
  border-radius: 0 !important;
  animation: none;
  transform: translate(0);
}

.popupContainer {
  line-height: 0;
}

.container {
  flex: 1 1;
  height: 100%;
  overflow-y: auto;
  padding-right: 6rem;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.popupHeader {
  padding: 6rem 6rem 0rem 6rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.25rem;
    background: colors.$light-gray-40;
    position: absolute;
    left: 0;
  }
}

.title {
  @include reset.indents;
  @include text-style.heading-18;
}

.icon {
  @include common.control-icon;
  color: colors.$gray-60;
}

.headerContainer {
  margin-bottom: 6rem;
  display: flex;
  justify-content: space-between;
}
