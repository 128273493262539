@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/sizes';
@use '/src/assets/styles/variables/reset';

.body {
  @include reset.button;
  gap: 1rem;
  cursor: pointer;
  transition: opacity 0.2s;
  position: relative;
  display: flex;
  align-items: center;
  color: colors.$dark-gray-140;

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &.disabled.subtle {
    background-color: transparent;
  }
}

.underlined {
  border-bottom: 1px solid colors.$light-gray-40;

  .count {
    background-color: colors.$light-gray-40;
    font-weight: 600;
  }

  &:hover {
    font-weight: 600;
    border-bottom: 1px solid colors.$gray-70;

    .icon {
      color: colors.$dark-gray-140;
    }
  }

  &.active {
    font-weight: 600;
    border-bottom: 2px solid colors.$blue-70;

    .icon {
      color: colors.$dark-gray-140;
    }
  }
}

.subtle {
  .count {
    background-color: colors.$light-gray-50;
    font-weight: 600;
  }

  &:hover {
    background-color: colors.$light-gray-20;

    .icon {
      color: colors.$dark-gray-140;
    }
  }

  &.active {
    font-weight: 600;
    background-color: colors.$light-gray-30;

    .icon {
      color: colors.$dark-gray-140;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 2rem;

  .count {
    background-color: colors.$light-gray-50;
    font-weight: 600;
  }

  .icon {
    background-color: colors.$light-gray-10;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 1px solid colors.$gray-60;
  }

  &.active {
    .icon {
      background-color: transparent;
      border: 5px solid colors.$blue-60;
    }
  }
}

.lg {
  @include text-style.ui-14;
  .count {
    padding: 0 2rem;
    border-radius: 3rem;
    margin-left: 1rem;
  }

  &.underlined {
    padding-bottom: 2rem;
  }

  &.subtle {
    padding: 2rem 4rem;
    border-radius: 1.5rem;
  }
}

.md {
  @include text-style.ui-14;
  .count {
    @include text-style.heading-12;
    padding: 0 1rem;
    border-radius: 2rem;
    margin-left: 0.5rem;
  }

  &.underlined {
    padding-bottom: 1rem;
  }

  &.subtle {
    padding: 1rem 3rem;
    border-radius: 1.5rem;
  }
}

.sm {
  @include text-style.ui-12;
  .count {
    padding: 0 1rem;
    border-radius: 2rem;
    margin-left: 0.5rem;
  }

  &.underlined {
    padding-bottom: 1rem;
  }

  &.subtle {
    padding: 0 2rem;
    border-radius: 1rem;
  }
}

.icon {
  color: colors.$gray-60;
}

.label {
  &::before {
    display: block;
    content: attr(text-content);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.loader{
  background-color: colors.$light-gray-10;
  border-radius: 6px;
}
