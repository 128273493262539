@use 'src/assets/styles/variables/colors';

.placeholder {
  user-select: none;
  color: colors.$gray-70;
}

.default {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
  color: colors.$dark-gray-140;

  &.disabled {
    color: colors.$gray-70;
  }
}

.counter {
  color: colors.$blue-70;
  &-tooltip {
    display: inline;
  }
}

.multi {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  padding-right: 1rem;
}

.contentTooltip {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  width: 100%;
  margin: 2rem 1rem;
}

.icon {
  min-width: 24px;
  path {
    fill: colors.$gray-60;
  }
}

.cloudIcon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.content {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  width: 100%;
}
