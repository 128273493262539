@use 'src/assets/styles/variables/text-style';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.modal {
  width: 719px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.sectionTitle {
  @include text-style.ui-14-semi;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
}
