
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.full {
  grid-column: 1 / 3;
}
