@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';


.icon {
  display: inline-block!important;
  vertical-align: bottom;
  color: colors.$gray-70;
  &__left {
    margin-left: 2rem;
  }
  &__right {
    margin-right: 2rem;
  }
}

.imgBorder {
  border-radius: 4rem;
}

.info {
  padding: 5rem;
  display: flex;
  gap: 5rem;
  border-radius: 6px;
  &__icon {
    min-width: 6rem;
    min-height: 6rem;
  }
  &-wrap {
    &_text {
      .info {
        background-color: colors.$blue-20;
        border: 1px solid colors.$blue-30;
        &__icon {
          color: colors.$blue-60;
        }
      }
    }
    &_warn {
      .info {
        background-color: colors.$red-20;
        border: 1px solid colors.$red-30;
        &__icon {
          color: colors.$red-60;
        }
      }
    }
  }
}

.screen {
  @include common.modal-enter-animation();
  //отключить анимацию на сафари
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      animation: none;
      transform: translate(0);
    }
  }
  display: flex;
  flex-direction: column;
  @include text-style.table-number-14();
  strong {
    color: colors.$dark-gray-140;
  }
  &-subtitle {
    display: block;
    @include text-style.paragraph-16();
    font-weight: 600;
    &_icon {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.bold {
  font-weight: 600;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.jc-end {
  display: flex;
  justify-content: flex-end;
}

.list {
  margin: 0;
  padding: 0 0 0 6rem;
}

.img {
  border: 1px solid #EEF0F2;
  border-radius: 8px;
  width: 100%;
}
