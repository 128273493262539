@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

$cell-paddings: 2rem 0rem calc(2rem - 1px) 4rem;

.container {
  position: relative;
  height: 100%;
  display: block;
  max-width: 100%;
  overflow-x: auto;
  &.scrollDisabled {
    overflow: hidden;
    thead {
      pointer-events: none;
    }
  }
}

.table {
  width: 100%;
  height: 100%;
  border-spacing: unset;
  border-collapse: collapse;

  // фикс firefox, при высоте таблицы в 100%, thead получает рандомную высоту
  @supports (-moz-appearance:none) {
    height: unset;
  }

  &.fullPageTable {
    min-width: 360rem;
  }

  tbody {
    position: relative;
    height: 100%;
  }
}

.tr {
  transition: background-color 0.1s;
  &:nth-child(even) {
    background-color: colors.$light-gray-10;
  }
  &:hover {
    background-color: colors.$blue-10;
  }
  &.selected {
    background-color: colors.$blue-20;
  }
  &.clickable {
    cursor: pointer;
  }
}

.th {
  @include text-style.ui-14;
  padding: $cell-paddings;
  border-bottom: 1px solid colors.$light-gray-30;
  color: colors.$gray-90;
  font-weight: normal;
}

.th:hover {
  color: colors.$dark-gray-150;
}

.td {
  @include text-style.ui-14;
  border-bottom: 1px solid colors.$light-gray-30;
  padding: $cell-paddings;
  color: colors.$dark-gray-140;
}

.noData {
  padding: $cell-paddings;
  text-align: center;
}

.tableLoader {
  color: colors.$gray-60;
  display: flex;
  justify-content: center;
}

.operationContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.operations {
  pointer-events: unset;
  &Hidden {
    pointer-events: none;
  }
}

.plug {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10rem);
  background-color: colors.$white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
