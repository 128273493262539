@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/shadows.scss';

.selectorsList {
  margin: 0 0 2rem 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
}

.appendButtonText {
  color: colors.$blue-70;
  padding: 0;
}

.tootlipSizeContainer {
  max-width: 44.25rem;
}

.tootlipPriceContainer {
  max-width: 43.25rem;
}

.addIcon {
  color: colors.$blue-70;
}
