@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style.scss';

:global {
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    background: colors.$white;
    padding: 0;
    position: static;
    border-bottom: none;
    height: fit-content;
  }

  .react-datepicker__month-wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-column-gap: 4rem;
  }

  .react-datepicker__month-text {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin: 0 !important;
    padding: 9px 0;
    border-radius: 6px;
    transition: background-color 0.3s;

    &:hover {
      background: colors.$light-gray-10;
      border-radius: 6px;
    }

    &:active {
      background: colors.$light-gray-30;
    }
  }

  .react-datepicker__monthPicker {
    display: grid;
    margin: 0;
    padding: 4rem 2rem 2rem 2rem;
    grid-template-rows: 1fr;
    grid-row-gap: 4rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 657px;
  height: 386px;
  border: none;
  background: colors.$white;
  box-shadow: shadows.$elevation-2;
  border-radius: 8px;
}

.headerInterval {
  display: flex;
  align-items: flex-start;
  padding: 2rem 2rem 0 2rem;
  border-bottom: 1px solid colors.$light-gray-40;
  margin-bottom: 2rem;

  &Title {
    @include text-style.ui-14;
    color: colors.$gray-100;
    margin-left: 1.5rem;

    &Container {
      display: flex;
      align-items: center;
      margin-right: 6rem;
    }

    &Icon {
      color: colors.$gray-70;
      width: 20px;
      height: 20px;
    }
  }
}

.content {
  display: flex;
  padding-bottom: 2rem;
  border-bottom: 1px solid colors.$light-gray-40;
  height: 100%;
  width: 100%;
}

.fastDates {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 46.25rem;

  &Header {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid colors.$light-gray-40;

    &Title {
      @include text-style.ui-14;
      color: colors.$gray-100;
      margin-left: 1.5rem;
    }

    &Icon {
      color: colors.$gray-70;
      width: 20px;
      height: 20px;
    }
  }

  &Variants {
    display: grid;
    grid-row-gap: 1rem;
    padding: 2rem 3rem 2rem 0;
  }
}

.fastDate {
  & > div {
    justify-content: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  gap: 4rem;
}

.periodLabel{
  margin-right: 5rem;
  @include text-style.ui-12;
  display: flex;
  align-items: center;
  color: colors.$gray-80;
}
