@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.modalContainer {
  display: flex;
  justify-content: space-between;

  @media (max-height: 880px) {
    max-height: 700px;
  }

  @media (max-height: 730px) {
    max-height: 590px;
  }

  .blockTitle {
    padding-bottom: 4rem;
  }
}

.modalLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.connectionBlock {
  padding-bottom: 6rem;
  color: colors.$gray-100;
  max-width: 150rem;
  &:last-child {
    padding-bottom: 0;
  }
}

.blockText {
  margin-top: 2rem;
  color: colors.$gray-80;
}

.tab {
  &-list {
    position: relative;
    gap: 10rem;
    box-shadow: inset 0 -1px colors.$light-gray-40;
    button {
      display: flex;
      justify-content: center;
    }
  }
  &-content {
    padding-top: 2rem;
    height: 100%;
    min-height: 200px;

    :global {
      .rc-table {
        .rc-table-row-level-0:nth-child(even) {
          td {
            background-color: colors.$white;
          }
        }
      }
      .rc-table-header {
        top: -24px !important;
      }

      .rc-table-expanded-row {
        .rc-table-cell {
          box-shadow: none;
        }
      }

      .rc-table-expand-icon-col {
        width: 18px;
      }

      .rc-table-row-expand-icon-cell {
        padding-top: 2.5rem !important;
      }

      .rc-table-expanded-row {
        td {
          padding: 0 !important;
        }
      }
    }
  }
  &-fake {
    flex: 1 1 50%;
    border-bottom: 1px solid colors.$light-gray-40;
  }
}

.blockContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
}

.blockContainerSelect {
  display: flex;
  gap: 4rem;
  align-items: flex-start;

  .btn {
    margin-top: 7rem;
  }
}

.modalRight {
  padding: 8rem;
  min-width: 166rem;
  background: colors.$light-gray-20;
  border-radius: 0 8px 8px 0;
  flex-direction: column;
  gap: 8rem;

  .rightHeader {
    margin-top: 1rem;
    color: colors.$dark-gray-110;
  }
}

.footer {
  padding-top: 8rem;
}

.btns {
  display: flex;
  padding-right: 8rem;
}

.btn {
  width: 50%;
  height: fit-content;
}

.btn:first-child {
  margin-right: 4rem;
}

.formSelect {
  width: 391px;
}

.formWrap {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 6rem;
}
