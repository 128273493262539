@use 'src/assets/styles/variables/layout.scss';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/common.scss';
@use 'src/assets/styles/variables/text-style.scss';

.icon {
  cursor: pointer;
  @include common.control-icon;
  color: colors.$gray-60;
}

.innerPopupContainer {
  position: absolute;
  top: 0;
  right: 0;
  border: none !important;
  z-index: 2;
}

.modalContainer {
  position: relative;
  @include common.modal-enter-animation;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: overlay;
  overflow-y: auto;
  padding-right: 6rem;
}

.headerContainer {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border-bottom: 1px solid colors.$light-gray-40;
}

.modalTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6rem;
  border-bottom: 1px solid colors.$light-gray-40;
}

.footer {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  padding-right: 6rem;
}

.modalInner {
  display: flex;
  flex-direction: column;
  padding: 0;

  > div {
    animation: none;
    transform: translate(0);
    padding: 6rem 6rem 8rem 6rem;
  }
}

.modalWrapper{
  border-radius: 0 !important;
  animation: none;
  transform: translate(0);
}

.innerPopupContainer {
  position: absolute;
  top: 0;
  right: 0;
  border: none !important;
  z-index: 2;
}

.modalContent{
  padding: 0;
}

.footer{
  padding: 6rem;
}

.innerPopupContent {
  width: 140rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.success {
  color: colors.$green-60;
  display: inline-block;
}

.error {
  color: colors.$red-60;
  display: inline-block;
}.innerPopupText {
   @include text-style.ui-14-semi;
 }

.avatarIcon {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  cursor: pointer;
}

.bodyContauner {
  height: 100%;
}

