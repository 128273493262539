@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/common.scss';

.listItem {
  display: flex;
  list-style-type: none;
  padding: 2rem 0;
  gap: 2rem;

  &.md {
    padding-bottom: 0;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  gap: 4rem;
  color: colors.$dark-gray-110;
}

.deleteIcon {
  flex-shrink: 0;
  @include common.control-icon;
  margin: 1rem 0;

  &.hidden {
    visibility: hidden;
  }
}
