@use 'src/assets/styles/variables/adaptive-resolutions';

.filter {
  padding: 4rem;
  display: block;
  z-index: 3;
  @include adaptive-resolutions.desktop-min-width{
    padding: 3rem;
  }
}
