/* inter-regular - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-regular.woff') format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-regular.ttf') format('truetype')
}
/* inter-600 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-600.woff') format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-600.ttf') format('truetype')
}
/* inter-700 - latin_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-700.woff') format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/Inter/inter-v8-latin_cyrillic-700.ttf') format('truetype')
}
/* roboto-mono-regular - latin_cyrillic */
