@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.title {
  display: flex;
  align-items: center;
  color: colors.$gray-100;
}

.container {
  display: grid;
  margin-bottom: 4rem;
}

.icon {
  cursor: pointer;
  margin-left: 1.5rem;
  color: colors.$gray-60;
  transition: color 0.3s;

  &:hover {
    color: colors.$gray-90;
  }

  &.active {
    color: colors.$dark-gray-150;
  }
}

.hintContent {
  overflow: hidden;
  height: 0;
  margin-top: 0;
  padding: 0;

  &.active {
    position: relative;
    height: fit-content;
    overflow: visible;
    margin-top: 2rem;
    padding: 4rem;
    background: colors.$light-gray-10;
    color: colors.$gray-100;
    border-radius: 6px;
  }

  //.rect {
  //  position: absolute;
  //  top: -5px;
  //  width: 10px;
  //  height: 10px;
  //  transform: rotate(45deg);
  //  background: colors.$light-gray-10;
  //}

  p {
    @include reset.indents;
    @include text-style.ui-12;
    color: colors.$gray-100;
    white-space: break-spaces;
  }

  a {
    color: colors.$blue-70;
    font-weight: bold;
  }
}
