.logo {
  &-icon {
    display: inline-block;
    width: 38.5rem;
    height: 28px;

    &.astrokite {
      width: 26.5rem;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    color: #000;
  }
  &-astrokite {
    display: grid;
    grid-template-columns: 24px 82px;
    grid-row-gap: 1rem;
    align-items: center;
  }
}
