@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';

.container {
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(26, 32, 36, 0.5);

  &.hidden {
    background: transparent;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  box-shadow: shadows.$block;
  border-radius: 8px;
  padding: 0 !important;
  width: 640px;
  max-height: 80%;
}

.content {
  padding: 6rem 6rem 8rem;
  height: 100%;
  overflow: auto;
}
