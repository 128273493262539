@use 'src/assets/styles/variables/colors';

.inputsContainer {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.row {
  display: grid;
  grid-column-gap: 4rem;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 100%;
}

.column {
  display: grid;
  grid-row-gap: 4rem;
  max-width: 800px;
}

.footer {
  border-top: 1px solid colors.$light-gray-20;
  padding: 6rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  button {
    width: 100%;
  }
}

.tableButton {
  width: 100%;
}
