@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/layout';
@use '/src/assets/styles/variables/text-style';

div {
  .colorMark {
    position: absolute;
    width: 5px;
    height: calc(100% + 22px);
    top: -3px;
    left: 0;

    &_red {
      background-color: colors.$red-60;
    }

    &_yellow {
      background-color: colors.$yellow-70;
    }

    &_green {
      background-color: colors.$teal-70;
    }

    &_purple {
      background-color: colors.$purple-70;
    }
  }
  .container {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 4rem;
    margin-bottom: 4rem;
    box-shadow: none;
    background-color: colors.$white;
    transition: background-color 0.2s;
    border: 1px solid colors.$light-gray-20;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &:hover {
      background-color: colors.$light-gray-20;
      .statusIndicator {
        border: 1px solid colors.$light-gray-40;
      }
    }

    .statusIndicator {
      position: relative;
      border: none;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      background-color: transparent;
      margin-left: 2rem;
      top: -4px;
      cursor: pointer;

      &.showed {
        visibility: hidden;
        border: 1px solid colors.$light-gray-50;
        .statusIndicatorInner {
          visibility: hidden;
        }
        &:active {
          border: 1px solid colors.$blue-70;
          background-color: transparent;
        }
      }

      &Inner {
        position: absolute;
        display: block;
        background-color: colors.$blue-70;
        width: 2rem;
        height: 2rem;
        top: 50%;
        left: 50%;
        margin: -1rem 0px 0px -1rem;
        border-radius: 50%;
      }

      &:hover {
        background-color: colors.$light-gray-40;
      }

      &:active {
        border: 1px solid colors.$blue-70;
        background-color: colors.$blue-30;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .id {
    @include text-style.ui-12;
    color: colors.$gray-70;
  }

  .avatar {
    width: 8rem;
    height: 8rem;
  }

  .systemAvatar {
    width: 8rem;
    height: 8rem;
  }

  .author {
    @include text-style.ui-14;
    color: colors.$dark-gray-150;
  }

  .title {
    color: colors.$dark-gray-150;
    margin: 0;
    @include text-style.ui-14-semi;
  }

  .text {
    @include text-style.ui-14;
    color: colors.$gray-100;
    margin: 0;
    white-space: pre-wrap;
  }

  .timeIcon {
    margin-right: 1rem;
    color: colors.$gray-80;
  }

  .date {
    @include text-style.ui-12;
    color: colors.$gray-70;
    margin-right: 2rem;
  }

  .time {
    @include text-style.ui-12;
    color: colors.$gray-70;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }

  .dateContainer {
    display: flex;
    align-items: center;
  }
}

.tooltip-wrapper{
  &.showed{
    visibility: hidden;
  }
}
