@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.statusBarWrapper {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2.5rem 4.5rem;
}

.statusBarText {
  @include text-style.ui-14;
  color: colors.$dark-gray-150;
  margin-left: 2rem;
}

.resolved{
  background: colors.$green-20;
}

.rejected{
  background: colors.$red-20;
}

.pending{
  background: colors.$blue-10;
}

.storyWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  background: white;
  padding: 10rem;
}

.resolveIcon {
  color: colors.$green-60;
}

.pendingIcon {
  color: colors.$blue-60;
}

.rejectedIcon {
  color: colors.$red-60;
}
