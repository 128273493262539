@use '/src/assets/styles/variables/colors';

.toastLoadingContainer {
  background: colors.$purple-10;
  border: 1px solid colors.$purple-20;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
}

.colorLoader {
  color: colors.$purple-80;
}
