@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(26, 32, 36, 0.5);
  outline: none;

  &.hidden {
    background: transparent !important;
  }
}

.wrapper {
  border-radius: 8px;
  background: colors.$white;
  box-shadow: shadows.$block;
  padding: 8rem;
}
