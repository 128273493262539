@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/adaptive-resolutions';

.button {
  @include reset.button;
}

.body {
  cursor: pointer;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  color: colors.$gray-70;
  align-items: center;

  .wrapper {
    display: flex;
    gap: 2rem;
    align-items: center;
    min-width: 0;
  }
  .text {
    @include text-style.ui-14;
    color: colors.$dark-gray-150;
  }
  .icon {
    display: flex;
    flex-shrink: 0;
    transition: transform 0.2s, color 0.2s;
  }
  .chevron {
    transform: rotate(0deg);
  }

  &:active{
    color: colors.$blue-80;
  }

  &:hover,
  &.isOpened {
    background-color: colors.$blue-20;
  }

  &.isActive {
    background-color: colors.$blue-30;
    color: colors.$blue-70;
    cursor: default;
    pointer-events: none;
    .text {
      font-weight: 600;
    }
    .chevron {
      transform: rotate(180deg);
      color: colors.$dark-gray-150;
    }
  }

  &.disabled {
    pointer-events: none;
    .text,
    .icon {
      opacity: 0.4;
    }
  }

  &.isSubItem {
    display: flex;
    padding: 1rem 2rem 1rem 3rem;
    border-radius: 6px;

    .wrapper {
      gap: 1.5rem;
      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        margin: 2.5rem;
        background-color: transparent;
        border-radius: 50%;
      }
    }

    .text {
      @include text-style.ui-14;
      color: colors.$dark-gray-140;
    }

    &.isActive {
      background-color: colors.$blue-30;
      .text {
        @include text-style.ui-14-semi;
        color: colors.$blue-70;
      }

      .wrapper::before {
        background-color: currentColor;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.subItems {
  display: none;
  flex-direction: column;
  gap: 1rem;
  div{
    margin-bottom: 0;
  }
}

.subWrapper {
  margin: 2rem 0;
}

.popup {
  border: 1px solid colors.$light-gray-10;
}

.isHoldsPopup {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: currentColor;
    right: 1.5rem;
    bottom: 1.5rem;
    border-radius: 50%;
  }
}


.isActive + .subItems {
  display: flex;
}

:global {
  .popup-content {
    :local {
      .subItems {
        display: flex;
      }
    }
  }
}
