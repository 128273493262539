@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/reset';

.loadingPageContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: colors.$light-gray-10;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  animation: visible .4s ease-out forwards;
}

@keyframes visible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.contentContainer {
  position: relative;
  text-align: center;
}

.logoContent {
  width: 11.25rem;
  height: 12.5rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.fullLogoContent{
  max-width: 110px;
  max-height: 94px;
  margin-left: auto;
  margin-right: auto;
}

.logoContentTitle {
  max-width: 33.75rem;
}

.logoText {
  @include reset.text;
  @include text-style.ui-12;
  color: colors.$gray-70;
}
