@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/colors';

.wrapper {
  @include text-style.paragraph-14;

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  grid-template-columns: 24px 1fr 24px;
  padding: 3rem;
  border-radius: 1.5rem;

  .openContent {
    padding-top: 0.6rem;
    max-height: 250rem;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }

  .closeContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .chevronUp{
    transform: rotate(-180deg);
  }

  .chevron{
    transition: all 0.3s;
  }

  .contentWrap{
    margin: 0;
  }

  .title {
    @include text-style.ui-14-semi;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .internalLink {
    display: block;
  }

  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.cannotClose {
    grid-template-columns: 24px 1fr;
  }

  &.danger {
    background-color: colors.$red-20;
    border: 1px solid colors.$red-30;

    .icon {
      color: colors.$red-60;
    }
  }

  &.info {
    background-color: colors.$blue-20;
    border: 1px solid colors.$blue-40;

    .icon {
      color: colors.$blue-60;
    }
  }

  &.warning {
    background-color: colors.$yellow-10;
    border: 1px solid colors.$yellow-50;

    .icon {
      color: colors.$yellow-70;
    }
  }

  &.success {
    background-color: colors.$green-20;
    border: 1px solid colors.$green-40;

    .icon {
      color: colors.$green-60;
    }
  }
}

.close {
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
  align-self: flex-start;
  color: colors.$dark-gray-150;
  opacity: 0.4;

  &:hover {
    opacity: 0.8;
  }
}
