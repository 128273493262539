@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/common';

.container {
  width: 150rem;
  position: relative;
  .close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    @include common.control-icon;
  }
  .body {
    padding: 14rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    .icon {
      width: 14rem;
      height: 14rem;
      padding: 4rem;
      border-radius: 50%;
      &.answer {
        color: colors.$yellow-70;
        background-color: colors.$yellow-30;
      }
      &.notification {
        color: colors.$green-70;
        background-color: colors.$green-30;
      }
      &.warning {
        color: colors.$red-70;
        background-color: colors.$red-30;
      }
      &.info {
        color: colors.$blue-70;
        background-color: colors.$blue-20;
      }
    }
    .contentWrapper {
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-evenly;
    gap: 4rem;
    .button {
      width: 50%;
    }
  }
}
