@use 'src/assets/styles/variables/colors';

.ya {
  //min-height: 590px;
  display: flex;
  flex-direction: column;
  &-header {
    padding: 0 0 6rem;
    border-bottom: 1px solid colors.$light-gray-20;
  }
  &-stepper {
    padding: 6rem 0;
  }
  &-form {
    flex: 1 1;
  }
  &-warning {
    //padding-top: 6rem;
  }
}
