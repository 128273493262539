@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.innerPopupContent {
  width: 140rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.role {
  @include text-style.ui-12;
  color: colors.$gray-80;
  margin: 0;
  padding: 2rem 5rem;
}

.companyData{
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  @include text-style.ui-14;

  svg{
    transform: none !important;
  }
}

.userDataWrapper {
  cursor: pointer;

  &:hover {
    background-color: colors.$light-gray-10;
  }

  &:active {
    background-color: colors.$light-gray-30;
  }
}

.userData {
  padding: 2rem 5rem;
}


.name {
  @include text-style.ui-14;
  color: colors.$dark-gray-150;
}

.email {
  font-size: 3rem;
  line-height: 6rem;
  color: colors.$gray-70;
}

.divider {
  height: 1px;
  background-color: colors.$light-gray-30;
}

.link {
  padding: 2rem 5rem;
  border-radius: 1rem;
  color: colors.$dark-gray-150;
  display: block;
  line-height: 6rem;
  text-decoration: none;
  cursor: pointer;


  &:hover {
    background-color: colors.$light-gray-10;
  }

  &:active {
    background-color: colors.$light-gray-30;
  }
}


.popupContent {
  min-width: 70rem;
}
