@use '/src/assets/styles/variables/text-style';

.span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9rem;
  max-width: 85rem;
}

.text {
  @include text-style.paragraph-14;
}
