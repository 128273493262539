@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';


.wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  padding: 4.5rem 2rem 5.5rem 53.75rem;
  border-radius: 8px;
  background: colors.$blue-100;
  height: 160px;
  justify-content: center;
  margin-bottom: 6rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 4;
}

.title {
  @include text-style.paragraph-16-semi();
  color: colors.$white;
}

.text {
  @include text-style.paragraph-14();
  color: colors.$white;
  opacity: 0.8;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.astronaut {
  position: absolute;
  top: 17px;
  left: 13px;
  z-index: 3;
  transform: rotate(-7.44deg);
}

.moreStars {
  position: absolute;
  top: -6px;
  left: 6px;
  z-index: 2;
  transform: rotate(9.83deg);
  width: 304.2px;
  height: 175.98px;
}

.blueStars {
  position: absolute;
  top: 3px;
  right: 67px;
  z-index: 2;
  transform: rotate(35.62deg);
}

.grayStars {
  position: absolute;
  bottom: -6px;
  right: 12px;
  z-index: 2;
  transform: rotate(35.62deg);
}

.button {
  width: fit-content;
}
