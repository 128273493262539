@use 'src/assets/styles/variables/colors';

.item {
  --option-icon-color: #b0babf;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;

  min-height: 7rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  transition: background-color .2s ease-in;
  &:hover {
    --option-icon-color: #252c32;

    background-color: colors.$light-gray-10;
  }

  &:active {
    --option-icon-color: #252c32;

    background-color: colors.$light-gray-30;
  }

  &.disabled {
    --option-icon-color: #b0babf;

    opacity: 0.5;
    background-color: colors.$white;
  }
}

.container {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: calc(100% - 32px);

  &.withoutCheckmark {
    width: 100%;
  }
}

.icon {
  flex-shrink: 0;
}

.check {
  color: colors.$dark-gray-140;
}

.chevron {
  transform: rotate(180deg);
  color: colors.$gray-90;
}

.optionIcon {
  transition: color .2s ease-in;
  color: var(--option-icon-color);
}

.cloudIcon {
  width: 24px;
  height: 24px;
}
