$common: 0 0 1px rgba(26, 32, 36, 0.32);

$elevation-1: $common, 0 1px 2px rgba(91, 104, 113, 0.32);
$elevation-2: $common, 0 4px 8px rgba(91, 104, 113, 0.24);
$elevation-3: $common, 0 8px 16px rgba(91, 104, 113, 0.24);
$elevation-4: $common, 0 12px 24px rgba(91, 104, 113, 0.24);
$elevation-5: $common, 0 24px 32px rgba(91, 104, 113, 0.24);
$elevation-6: $common, 0 40px 64px rgba(91, 104, 113, 0.24);
$block: 0px 7px 15px rgba(0, 0, 0, 0.05);

$header-popup: -15px 7px 30px rgba(0, 0, 0, 0.05);
