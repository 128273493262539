@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.withBorder {
  border-radius: 3rem;
  border: 1px solid colors.$light-gray-40;
}

.user {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
  min-width: 0;
  &-avatar {
    border-radius: 50%;
    font-size: x-small;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-name {
    @include reset.indents;
    color: colors.$dark-gray-140;
    overflow: hidden;
  }
}

.sm {
  @include text-style.ui-12;
  .user-avatar {
    width: 5rem;
    height: 5rem;
  }
}
.md {
  @include text-style.ui-14;
  .user-avatar {
    width: 6rem;
    height: 6rem;
  }
}

.disabled {
  opacity: 0.3;
}
