@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';


:global {
  .rc-table {
    flex: 1 1;

    &-row-active {
      .rc-table-cell {
        background-color: colors.$blue-20!important;
      }
    }

    &-row-expand {
      .rc-table-cell {
        background-color: colors.$blue-10!important;
      }
    }

    table {
      border-spacing: 0;
      width: 100%;
    }

    &-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &-header {
      border: none;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    &-thead {
      width: 100%;
    }
    &-body {
      flex: 1 1;
      margin-left: 2rem;
      margin-right: 2rem;
      overflow: auto;
      border: none;
    }
    &-content {
      overflow-x: auto;
    }
    &-cell {
      vertical-align: top;
      background: colors.$white;
      padding: 2rem 4rem calc(2rem - 1px)!important;
      @include text-style.ui-14();
      color: colors.$dark-gray-140;
      &-fix-right {
        &:after {
          box-shadow: inset 9px 0 0 -8px colors.$light-gray-30!important;
        }
        &-first {
          box-shadow: inset 9px 0 0 -8px colors.$light-gray-30!important;
          &:after {
            box-shadow: none!important;
          }
        }
      }
      &-fix-left {
        &:after {
          box-shadow: inset 9px 0 0 -8px colors.$light-gray-30!important;
        }
      }
      &-row {
        &-hover {
          background-color: colors.$blue-10!important;
        }
      }
    }

    &-row-level-1 {
      td {
        &:first-child {
          padding-left: 12rem!important;
        }
      }
    }

    &-placeholder {
      .rc-table-cell {
        background-color: colors.$white!important;
      }
    }

    th {
      background: colors.$white!important;
      border-color: transparent;
      border-bottom: 1px solid colors.$light-gray-30;
    }

    td {
      border-color: transparent;
      border-bottom: 1px solid colors.$light-gray-30;
    }

    tr {
      &:nth-child(2n) {
        td {
          background-color: colors.$light-gray-10;
        }
      }
    }
  }

  .rc-table-cell-checkbox {
    vertical-align: middle!important;
  }
}

.card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  &_selection {
    tr {
      cursor: pointer;
    }
  }
}

.operations {
  position: sticky;
  top: 0;
  z-index: 3;
}

.expand {
  &-icon {
    display: inline-block;
    vertical-align: text-top;
    svg {
      color: colors.$gray-100;
      transform: rotate(-90deg);
      transition: transform .2s ease-out;
    }

    &_active {
      svg {
        transform: rotate(0);
      }
    }
  }

  &-button{
    width: 24px;
    height: 24px;

    > div {
      padding: 0;
    }
  }
}

.plug {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;

  &_active {
    :global {
      .rc-table-body {
        table {
          height: 100%;
        }
      }
    }
  }
}
