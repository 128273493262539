@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 6rem 6rem 4rem 6rem;
  border-bottom: none;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info {
  margin-top: 2rem;
  @include text-style.ui-14;
  color: colors.$gray-80;
}

.title {
  @include text-style.heading-18;
  color: colors.$red-80;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.close {
  @include common.control-icon;
}
