@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: grid;
  grid-template-columns: var(--tariff-table-template);
  align-items: flex-start;
  background-color: colors.$white;
  border-bottom: 1px solid colors.$light-gray-30;

  &.odd {
    background-color: colors.$light-gray-10;
  }
}

.info {
  @include text-style.ui-14;
  padding: 8px;

  &.placeholder {
    color: colors.$gray-70;
  }

  &.price {
    display: flex;
    justify-content: flex-end;
  }
}
