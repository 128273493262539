.wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 8rem;
}

.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;
  &-text{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
}
