@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.optionsBlock {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
}

.title {
  @include text-style.ui-14-semi;
  color: colors.$dark-gray-110;
  margin-bottom: 2rem;
}

.list {
  @include reset.indents;
  @include text-style.ui-12;
  color: colors.$dark-gray-140;
  line-height: 2;
  padding-left: 5rem;
  margin-top: 2rem;
}
