.container {
  width: 100%;
  gap: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr))
}

.controls {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 2rem;
  grid-column-end: -1;
}
