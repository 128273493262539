@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.dark {
  background-color: colors.$dark-gray-130;
  color: colors.$light-gray-10!important;
}

.light {
  background-color: colors.$white;
  color: colors.$dark-gray-110!important;
}

.tooltip {
  display: inline-block;
  border-radius: 1rem;
  padding: 1rem 2rem;
  @include text-style.ui-12;
  max-width: 300px;
  &__text {
    margin: 0;
  }
}
:global {
  .tooltip-content {
    z-index: 1001 !important;
    filter: drop-shadow(0px 0px 1px rgba(26, 32, 36, 0.32)) drop-shadow(0px 8px 16px rgba(91, 104, 113, 0.24));
  }
}
