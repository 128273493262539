@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 6rem;
}

.header {
  margin: 0;
  @include text-style.paragraph-14;
  color: colors.$gray-100;
  max-width: 399px;
}

.buttonContainer {
  width: 55rem;
}

.title {
  @include text-style.heading-18;
  margin-right: 2rem;
}

.close {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
