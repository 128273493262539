@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/common';

.body {
  @include text-style.ui-14;

  display: block;
  color: colors.$dark-gray-140;

  .field {
    @include reset.input;

    width: 100%;
    border: 1px solid colors.$light-gray-40;
    background-color: colors.$white;
    border-radius: 6px;
    transition: border 0.2s;
    padding: 2rem 4rem;
    resize: none;
    height: 20rem;

    &.isError {
      border-color: colors.$red-60;
    }

    &:hover {
      border-color: colors.$gray-60;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &::placeholder {
      color: colors.$gray-70;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .error {
    @include common.filed-error-message;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  .description {
    @include text-style.ui-12;
    color: colors.$gray-70;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  &.disabled {
    .field {
      background-color: colors.$light-gray-10;
      border-color: colors.$light-gray-20;
      color: colors.$gray-60;
    }
    .caption {
      opacity: 0.4;
    }
  }
}
.icon {
  color: colors.$gray-60;
  flex-shrink: 0;
}

.hint {
  color: colors.$gray-60 !important;
  cursor: help;
}

.caption {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}
