@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/common.scss';

.successContainer {
  border-radius: 8px;
  padding: 6rem;
  overflow: visible;
  position: relative;
  background: colors.$white;
}

.container {
  overflow: visible;
  position: relative;

  .close {
    position: absolute;
    z-index: 1;
    top: 8rem;
    right: 8rem;
    @include common.control-icon;
  }

  .header {
    padding-bottom: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid colors.$light-gray-40;
    padding-right: 14rem;

    .title {
      @include text-style.heading-24;
      @include reset.indents;
    }
  }

  .content {
    position: relative;

    .overlay {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      opacity: 1;
      color: colors.$dark-gray-150;

      background-color: colors.$white;

      &.hidden {
        opacity: 0;
        transition: opacity 0.3s 0.2s;
        pointer-events: none;
      }

      .spinner {
        color: colors.$gray-60;
      }
    }
  }
}

.wideWindow {
  width: 960px !important;
  height: 560px;
}

.vCloudDirector {
  padding: 6rem 6rem 8rem;
}

.OpenStack {
  padding: 6rem 6rem 8rem;
}

.HyperV {
  padding: 6rem 6rem 8rem;
}

.container_1 {
  width: 960px;
  height: 560px;
}
