@use 'resolutions';

@mixin desktop-max-width() {
  @media(max-width: resolutions.$desktop-max-width){
    @content
  }
}

@mixin desktop-min-width() {
  @media(max-width: resolutions.$desktop-min-width){
    @content
  }
}

@mixin tablet-max-width() {
  @media(max-width: resolutions.$tablet-max-width){
    @content
  }
}

@mixin tablet-min-width() {
  @media(max-width: resolutions.$tablet-min-width){
    @content
  }
}
