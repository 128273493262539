@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.wrapper{
  display: flex;
  align-items: center;
  padding-bottom: 7.5rem;
}

.container{
  position: relative;
}

.dot{
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border:2px solid colors.$light-gray-40;
  &.ready{
    border: 2px solid colors.$blue-60;
  }
  &.prev{
    outline: 2px solid colors.$blue-60;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$blue-60
  }
  &-circle{
    position: absolute;
    left: 0;
    top: 0;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 8px solid colors.$blue-60;
    background: none;
  }
  &-text{
    position: absolute;
    top: 30px;
    left: 0;
    @include text-style.paragraph-14;
    color: colors.$dark-gray-140;
    max-width: 235px;
    min-width: 220px;
    @media(max-width: 1679px){
      max-width: 215px;
      min-width: 200px;
    }
    @media(max-width: 1439px){
      max-width: 170px;
      min-width: 169px;
    }
    @media(max-width: 1100px){
      max-width: 170px;
      min-width: 169px;
    }
  }
  &-error{
    outline:2px solid colors.$red-60;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$red-60;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
}

.line{
  width: 80.5rem;
  height: .5rem;
  background: colors.$light-gray-40;
  margin: 0 .5rem;
  &.active{
    background: colors.$blue-60;
  }
  &.noPolicy{
    width: 58rem;
  }
  @media(max-width: 1679px){
    width: 253px;
    &.noPolicy{
      width: 176px;
    }
  }
  @media(max-width: 1439px){
    width: 211px;
    &.noPolicy{
      width: 144px;
    }
  }
  @media(max-width: 1100px){
    width: 170px;
    &.noPolicy{
      width: 120px;
    }
  }
}

.edit{
  &-wrapper{
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
  &-cloudIcon{
    width: 6rem;
    height: 6rem;
  }
  &-cloudId{
    color: colors.$gray-100;
  }
}
