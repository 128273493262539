.body {
  display: flex;
  max-width: 100%;
  &-inner {
    display: flex;
    align-items: center;
    gap: 2rem;
    max-width: 100%;
  }
}

.icon {
  display: block;
  min-width: 6rem;
  width: 6rem;
  height: 6rem;
}

.textShorter {
  max-width: 131.25rem;
}