@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.inputSelect {
  display: flex;
  width: min-content;
}

:global {
  .selectWithInput__control {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border: 1px solid colors.$light-gray-20 !important;
    width: max-content !important;
    min-width: 23.5rem;
  }
}

:global {
  .select__control {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border: 1px solid colors.$light-gray-20 !important;
    width: 23.5rem;
  }
}

:global {
  .selectSource__control {
    border: 1px solid colors.$light-gray-20 !important;
    width: 23.5rem;
  }
}

.inputWithSelect {
  & > div {
    border-right: none !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: 1px solid colors.$light-gray-20 !important;
  }
  width: 15.3rem !important;
  .wrapper {
    background-color: aqua !important;
  }
}

.currencyBlock {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.iconContainer {
  font-size: 0;
  line-height: 0;
  color: colors.$gray-70;
}

.count {
  @include text-style.ui-14;
  color: colors.$dark-gray-140;
}

.equals {
  @include text-style.ui-14-semi;
  color: colors.$gray-70;
}
