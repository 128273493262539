@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/colors';

.badge {
  display: inline-flex;
  flex-basis: auto;
  align-items: center;
  gap: 2rem;

  &.lg,
  &.md {
    border-radius: 1.5rem;
    .text {
      @include text-style.ui-14;
    }
  }
  &.lg {
    padding: 1rem 3rem;
  }
  &.md {
    padding: 0 2rem;
  }
  &.sm {
    border-radius: 1rem;
    padding: 0 1rem;
    .text {
      @include text-style.ui-12;
    }
  }
}

.green {
  .text {
    color: colors.$green-80;
  }
  background-color: colors.$green-20;
}
.yellow {
  background-color: colors.$yellow-20;
  .text {
    color: colors.$yellow-90;
  }
}
.red {
  background-color: colors.$red-20;
  .text {
    color: colors.$red-80;
  }
}
.blue {
  background-color: colors.$blue-20;
  .text {
    color: colors.$blue-80;
  }
}
.teal {
  background-color: colors.$teal-20;
  .text {
    color: colors.$teal-80;
  }
}
.purple {
  background-color: colors.$purple-20;
  .text {
    color: colors.$purple-80;
  }
}
.gray {
  background-color: colors.$light-gray-20;
  .text {
    color: colors.$dark-gray-140;
  }
}

.icon {
  cursor: pointer;
  color: colors.$dark-gray-140;
}
