@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/shadows.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  margin: 0;
  @include text-style.heading-18;
}

.buttonContainer {
  display: none;
  padding: 1rem 7.5rem;
  height: 7rem;
  align-items: center;
  border-radius: 2rem;
  &:focus:enabled {
    background-color: transparent;
    outline: none;
    border: 1px solid colors.$blue-70;
  }
}

.buttonText {
  @include text-style.paragraph-14;
  line-height: 5rem;
}

.buttonActive {
  background-color: transparent;
  border-color: colors.$blue-70;
  color: colors.$blue-70;
}

.buttonsContainer {
  display: flex;
  gap: 2rem;
}
