@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  --tariff-table-template: 101px 420px 157px minmax(178px, 1fr) [end];
  --tariff-table-header-template: 286px var(--tariff-table-template);

  &.isFixRates {
    --tariff-table-template: 220px 100px 140px 94px 40px;
    --tariff-table-header-template: var(--tariff-table-template);
  }

  width: 100%;
  height: 100%;
  overflow: hidden;
  &-content{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    border-radius: 2rem;
    width: 100%;
    background-color: colors.$white;
  }
  &-plug{
    position: inherit !important;
    transform: none !important;
  }
  &-header{
    display: flex;
    align-items: center;
    column-gap: 4rem;
    &-link{
      display: flex;
      padding: 0 0 .5rem 0;
      align-items: flex-end;
      column-gap: 1rem;
      align-self: stretch;
      color: colors.$blue-70;
      @include text-style.ui-14;
    }
  }
}

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.plugTexts {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.page-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.databaseIcon {
  color: colors.$gray-70;
}

.usedContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    @include text-style.ui-14;

    &:first-child {
      color: colors.$gray-100;
      margin-right: 4px;
    }
  }
}

.clouds {
  display: flex;
  width: 100%;
  min-height: 20px;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}

.cloudsLoading {
  width: 100%;
  min-height: 26px;

  & > div {
    min-height: 26px;
  }
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;
  border-bottom: 1px solid colors.$light-gray-40;

  &.noEdit {
    gap: 24px;
  }
}

.cloudChip {
  display: flex;
  align-items: center;
  gap: 4px;
}

.editText {
  @include text-style.ui-12;
  color: colors.$gray-80;
  margin-left: 6px;
}

.icon {
  display: block;
  min-width: 6rem;
  width: 6rem;
  height: 6rem;
}

.tariffs {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.tariffsWrapper {
  height: 100%;
  overflow-y: auto;
}
