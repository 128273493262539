@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/common.scss';
@use 'src/assets/styles/variables/shadows.scss';

:global {
  .popup-notifications-content {
    position: fixed !important;
    top: 10rem !important;
  }
}

.wrapper {
  border: 1px solid transparent;
  border-radius: 8px;
  &.isVisibleLoading {
    background-color: colors.$purple-10;
    border-color: colors.$purple-20;
    padding: 1rem 1.5rem 1rem 2rem;
    cursor: pointer;
  }
}

.notificationBlock {
  display: flex;
  margin: -1px;
}
