@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: grid;
  grid-template-columns: var(--tariff-table-header-template);
  align-items: center;
  background-color: colors.$white;
  border-bottom: 1px solid colors.$light-gray-30;
}

.info {
  @include text-style.ui-14;
  white-space: nowrap;
  padding: 8px;
  color: colors.$gray-90;

  &.price {
    display: flex;
    justify-content: flex-end;
  }
}
