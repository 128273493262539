@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/font-family';

.toastTitle {
  @include reset.text;
  @include reset.indents;
  @include text-style.heading-14;
  font-family: font-family.$primary;
  text-transform: none;
  margin-bottom: 1rem;
}
