@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';


.pagination {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.paginationText {
  flex-shrink: 0;
}

.selectWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.root {
  padding: 3rem 4rem;
  display: flex;
  justify-content: space-between;
  background-color: colors.$white;
  border-top: 1px solid colors.$light-gray-40;
  border-radius: 0 0 8px 8px;
}

.pages {
  display: flex;
  align-items: center;
  &__btns {
    margin-left: 6rem;

    &__item {
      &:first-of-type {
        margin-right: 1rem;
      }

      .icon {
        padding: calc(1rem - 1px) calc(2rem - 1px);
      }
    }
  }
}
.control {
  min-width: 18rem;
}

.total {
  @include text-style.ui-14-semi;
  flex-grow: 0.5;
  text-align: center;
}

.items {
  display: flex;
  align-items: center;
  & > span {
    display: inline-block;
    margin-left: 2rem;
  }
}
