@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.wrapper {
  background-color: colors.$white;
  padding: 4rem;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  overflow-y: auto;
  &-content{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}

.edit{
  &-header{
    border-bottom: 1px solid #dde2e4;
    display: flex;
    justify-content: space-between;
    padding: 6rem;
  }
  &-title{
    @include text-style.heading-18;
  }
  &-icon{
    color: colors.$gray-60;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  &-footer{
    grid-column-gap: 4rem;
    display: grid;
    grid-auto-flow: column;
    border-top: 1px solid #dde2e4;
    padding: 6rem;
    width: 100%;
  }
}
