@use 'variables/font-family';
@use 'variables/sizes';
@use 'variables/colors';
@use 'variables/text-style';
@import '/src/assets/fonts/Inter.scss';
@import '/src/assets/fonts/RobotoMono.scss';
@import "libs";
@import "animations";

html {
  font-family: font-family.$primary;
  font-size: sizes.$base;
}

/* Works on Safari */
svg {
  width: 100%;
  height: 100%;
}

body {
  @include text-style.paragraph-14;
  margin: 0;
  background-color: colors.$light-gray-10;
  overflow-y: overlay;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: colors.$gray-60 rgba(60, 70, 78, 0.05);
}

*::-webkit-input-placeholder {
  opacity: 1;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1rem;
  border-radius: 2px;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: rgba(60, 70, 78, 0.05);
  border-radius: 2px;

  &:hover {
    background-color: rgba(60, 70, 78, 0.07);
  }

  &:active {
    background-color: rgba(60, 70, 78, 0.1);
  }
}

*::-webkit-scrollbar-thumb {
  background-color: colors.$gray-60;
  border-radius: 2px;

  &:hover {
    background-color: colors.$gray-100;
  }

  &:active {
    background-color: colors.$dark-gray-150;
  }
}

* {
  box-sizing: border-box;
  text-size-adjust: none;
}
