.wrapper {
  display: flex;
  gap: 6px;
  height: 100%;
  width: 100%;
}

.sidebar {
  height: 100%;
  width: 402px;
}

.content {
  height: 100%;
  width: 100%;
}
