.overlay {
  z-index: 100;
  background-color: rgba(26, 32, 36, 0.5);
  position: absolute;
  top: -20rem;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}
