@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/sizes.scss';

.selection {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: var(--selection-height);
  padding: var(--selection-padding);
  border: 1px solid colors.$light-gray-40;
  background: colors.$white;
  border-radius: 1.5rem;
  transition: all 0.3s ease-in;
  color: colors.$gray-70;

  &:hover {
    background: colors.$light-gray-10;
  }
  &:active {
    background: colors.$light-gray-20;
  }

  .withValue {
    color: colors.$gray-70;
  }

  &.disabled {
    background: colors.$light-gray-10;
    border-color: colors.$light-gray-20;
    pointer-events: none;
    cursor: auto;

    .arrow {
      opacity: 0.4;
    }
  }
}

.wrapper {
  position: relative;
}

.error {
  border-color: colors.$red-70;
}

.inner {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.value {
  flex: 2 1;
  min-width: 0;
  max-width: calc(100% - 24px);
}

.caption {
  @include text-style.paragraph-14;
  transition: opacity 0.3s ease-in;
  margin: var(--caption-margin);
}

.lg {
  --selection-height: 40px;
  --selection-padding: 8px 8px 8px 16px;
}

.md {
  --selection-height: 32px;
  --selection-padding: 4px 4px 4px 12px;
}

.sm {
  --selection-height: 24px;
  --selection-padding: 0 0 0 8px;
}

.arrow {
  color: colors.$gray-90;
}

.icons {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}
