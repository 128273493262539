@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/reset.scss';

.table {
  flex-grow: 1;
  height: 100%;
  padding: 0;
  box-shadow: none;
}

.blueIcon {
  color: colors.$blue-60;
}

.redIcon {
  color: colors.$red-70;
}

.headerDialog {
  margin-bottom: 6rem;
}

.textDialog {
  @include reset.indents;
  @include text-style.paragraph-16;
}

.text {
  color: colors.$gray-100;
}

.title {
  @include text-style.paragraph-16-semi();
}
