@use '/src/assets/styles/variables/colors';

.toastSuccessContainer {
  background: colors.$green-10;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
}

.colorIcon {
  color: colors.$green-70;
}

.btn {
  background-color: colors.$white;

  > div {
    margin: 0;
    border: 1px solid colors.$light-gray-40;
  }
}
