@use 'src/assets/styles/variables/colors';

.form {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.row {
  display: flex;
  justify-content: space-between;
  grid-gap: 4rem;
  border-top: 1px solid colors.$light-gray-30;
  padding: 4rem;

  &:last-of-type {
    border-bottom: 1px solid colors.$light-gray-30;
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.plusIcon {
  transform: rotate(45deg);
}

.appendButtonText {
  color: colors.$blue-70;
}

.inputError {
  margin-bottom: -2rem;
}

.delete {
  display: flex;
  align-items: center;
  width: 6rem;
  height: 8rem;
  color: colors.$gray-60;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: colors.$red-70;
  }
}

.row:first-child .delete {
  margin-top: 7rem;
}
