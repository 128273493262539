@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset';

.options {
  @include reset.ul;
}

.no-options {
  display: flex;
  justify-content: center;
  color: colors.$gray-70;
  user-select: none;
  padding: 0 2rem 1rem;
}
