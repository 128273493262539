@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/shadows';

.wrapper {
  display: grid;
  grid-row-gap: 2rem;
  padding: 2rem 2rem 4rem 4rem;
  background-color: colors.$white;
  border: 1px solid colors.$gray-60;
  border-radius: 8px;
  transition: background-color 0.3s, border 0.3s;
  cursor: pointer;

  &:hover {
    background-color: colors.$blue-10;
    border: 1px solid colors.$blue-60;

    .trigger {
      opacity: 1;
    }
  }

  &.isPopupOpen {
    background-color: colors.$blue-10;
    border: 1px solid colors.$blue-60;

    .trigger {
      opacity: 1;
    }
  }
}

.status {
  width: fit-content;
}

.nameContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  grid-template-columns: max-content;
  align-items: center;
}

.infoContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  grid-template-columns: max-content;
  width: fit-content;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.cloudName {
  @include text-style.paragraph-14;
}

.iconContainer {
  width: 24px;
  height: 24px;
}

.infoItem {
  @include text-style.ui-10;

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1.5rem;
  grid-template-columns: max-content;
  align-items: center;
  width: fit-content;
}

.infoIcon {
  color: colors.$gray-60;
}

.currencySign {
  @include text-style.ui-10;

  font-weight: 600;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid colors.$light-gray-10;

  box-shadow: shadows.$elevation-2;
}

.button {
  width: 100%;
  & > div {
    justify-content: flex-start;
  }

  &.isDelete {
    & > div {
      color: colors.$red-70;
    }
  }
}

.trigger {
  transition: opacity 0.3s, color 0.3s, background-color 0.3s;
  color: colors.$gray-80;
  opacity: 0;
  border-radius: 4px;

  &:hover {
    color: colors.$dark-gray-150;
    background-color: colors.$light-gray-10;
  }

  &.isActive {
    background-color: colors.$light-gray-30;
    color: colors.$dark-gray-150;
  }

  &:focus {
    color: colors.$dark-gray-150;
    outline: 3px solid colors.$blue-40;
  }
}

.cloud {
  overflow: hidden;
}
