@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset.scss';
@use 'src/assets/styles/variables/text-style.scss';

.imageContainer {
  display: flex;
  position: relative;
  width: 50rem;
  height: 50rem;
  border-radius: 6px;
  box-sizing: content-box;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 8rem;
  align-items: center;
  justify-content: center;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cameraIconContainer {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: colors.$blue-60;
  opacity: 0.3;
  top: 0;
  left: 0;
  transition: opacity 600ms;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.8;
  }
}

.cameraIcon {
  cursor: pointer;
  position: absolute;
  color: colors.$white;
  width: 32px;
  height: 32px;
}

.avatarPlug {
  line-height: 0;
  font-size: 0;
}

.closeButton {
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 8rem;
  height: 8rem;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: colors.$light-gray-20;
  color: colors.$gray-70;
  top: -7px;
  right: 30%;

  &:hover {
    background-color: colors.$light-gray-30;
  }

  &:active {
    background-color: colors.$light-gray-50;
  }

  &:disabled {
    background-color: colors.$light-gray-10;
    color: colors.$light-gray-50;
    cursor: auto;
  }
}

.imageBlockContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
