@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
  min-height: 40px;
  &-content{
    display: flex;
    align-items: center;
    column-gap: 4rem;
    &-link{
      display: flex;
      padding: 0 0 .5rem 0;
      align-items: flex-end;
      column-gap: 1rem;
      align-self: stretch;
      color: colors.$blue-70;
      @include text-style.ui-14;
    }
  }
}
