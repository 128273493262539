@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.list {
  display: flex;
  align-items: center;
}

.linkText{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: colors.$dark-gray-150;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.link {
  @include text-style.ui-12;
  $bg-color: colors.$light-gray-10;
  color: colors.$dark-gray-150;
  display: flex;
  align-items: center;
  padding-right: 2rem;
  background-color: $bg-color;
  border-radius: 8px;
  border: 1px solid $bg-color;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
  text-decoration: none;
  margin-right: 2rem;
  &-img {
    display: flex;
    margin-right: 2rem;
    width: 5rem;
  }
  &-btn {
    opacity: 0;
    transition: opacity 0.2s;
    margin-left: 2rem;
    border-radius: 50%;
    &__icon {
      color: colors.$gray-60;
    }
    &:hover {
      background: colors.$light-gray-10;
    }
  }
  &:hover {
    background: colors.$blue-10;
    border: 1px solid colors.$blue-10;
    .link-btn {
      opacity: 1;
    }
  }
  &.isActive {
    border: 1px solid colors.$light-gray-50;
    background: colors.$white;
  }
}
