@use '/src/assets/styles/variables/colors';

.link {
  color: colors.$blue-70;
  position: relative;
  transition: color 0.2s;
  &:active {
    color: colors.$blue-80;
  }
  text-decoration: none;
  &.disabled {
    pointer-events: none;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &.underline {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0.5rem;

      opacity: 0.2;
      background-color: colors.$blue-60;
      height: 1px;
      width: 100%;
      transition: opacity 0.2s, background-color 0.2s;
    }
    &:hover::after {
      opacity: 1;
    }
    &:active::after {
      opacity: 1;
      background-color: colors.$blue-80;
    }
  }
}
