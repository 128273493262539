@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/adaptive-resolutions';

$padding: 4rem;
.body {
  width: 65rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: $padding;
  background-color: colors.$white;
  transition: all 0.3s;
  box-shadow: shadows.$block;

  .menu {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;

    .section {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      &:not(:last-child) {
        border-bottom: 1px solid colors.$light-gray-30;
        padding-bottom: 4rem;
      }
    }
  }

  .chevron {
    transform: rotate(90deg);
    transition: transform 0.2s, color 0.2s;
  }

  .button {
    @include text-style.paragraph-16;
    width: 100%;
    cursor: pointer;
  }
  &.isMinified {
    width: calc(10rem + #{$padding} * 2);
    padding: 4rem;
    .chevron {
      transform: rotate(-90deg);
      color: colors.$blue-70;
    }
  }
}
