@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: colors.$white;
  border-radius: 8px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.03);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  border-bottom: 1px solid colors.$light-gray-30;
}

.title {
  @include text-style.ui-14();
}

.controls {
  display: grid;
  grid-column-gap: 4rem;
  grid-auto-flow: column;
  align-items: center;
}

.block {
  height: 100%;
  padding: 4rem 4rem 0 4rem;
}
