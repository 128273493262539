@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.settingItemActive{
  .settingItemWrapper{
    padding: 4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 1rem;
    border: 1px solid colors.$blue-20;
    background: colors.$blue-20;
    border-left: 2px solid colors.$blue-70;
    transition: all ease 0.3s;
  }

  .activeDirectoryIcon {
    color: colors.$blue-70;
  }

  .iconChevron{
    opacity: 1;
  }

  .settingItemTitle{
    text-transform: uppercase;
    @include text-style.ui-14-semi();
    color: colors.$blue-70;
    margin: 0;
  }

  :hover{
    cursor: pointer;
  }
}

.settingItem{
  .settingItemWrapper{
    padding: 4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 1rem;
    border: 1px solid colors.$blue-20;
    transition: all ease 0.3s;
  }

  .settingItemTitle{
    text-transform: uppercase;
    @include text-style.ui-14-semi();
    color: colors.$gray-90;
    margin: 0;
  }

  :hover{
    cursor: pointer;
    background: colors.$blue-10;

    .iconChevron{
      opacity: 1;
    }
  }
}

.iconChevron{
  color: colors.$blue-60;
  transform: rotate(180deg);
  margin-left: auto;
  opacity: 0;
  transition: opacity ease 0.3s;
}

.activeDirectoryIcon{
  color: colors.$gray-100;
}
