$base: 4px;

$xxxl: $base * 15; //60px
$xxl: $base * 9; // 36px
$xl: $base * 6; // 24px
$lg: $base * 4.5; // 18px
$md: $base * 4; // 16px
$sm: $base * 3.5; // 14px
$xs: $base * 3; // 12px
$xxs: $base * 2.5; // 10px
