@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/colors.scss';

.currenciesBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border: 1px solid colors.$light-gray-30;
  border-radius: 6px;
  margin-bottom: 2rem;
  max-height: 8rem;
}

.currencieBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
  &:not(:first-child) {
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 6rem;
      background-color: colors.$light-gray-30;
      left: 50%;
    }
  }
}

.bankSelect {
  margin-bottom: 2rem;
}

.currencieLabel {
  @include text-style.ui-12;
  color: colors.$gray-90;
}

.currencieValue {
  @include text-style.ui-14-semi;
  color: colors.$black;
}

.timeLabel {
  color: colors.$gray-70;
  @include text-style.ui-12;
}

.timeValue {
  @include text-style.ui-12;
  margin-bottom: 3rem;
}
