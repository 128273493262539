.page {
  animation: appear .5s cubic-bezier(1, 0.04, 0.64, 1.31) forwards;
  height: 100%;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
