.form {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
  padding-top: 4rem;
}

.rowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.full {
  grid-template-columns: 1fr;
}
