@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/shadows.scss';

.grid {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.iconContainer {
  width: 12rem;
  height: 12rem;
  flex-shrink: 0;
}

.texts {
  display: flex;
  flex-direction: column;
}

.version {
  @include text-style.ui-12;
  color: colors.$gray-70;
}
