@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

.cell {
  display: flex;
  align-items: center;
  color: colors.$gray-90;
  margin: 0 -0.5rem;
  font-weight: 400;
  @include text-style.ui-14();
  user-select: none;
  &__item {
    margin: 0 0.5rem;
  }
  &__sort {
    opacity: 0;
    transition: opacity .4s ease-in;
    &_active {
      opacity: 1;
    }
  }

  &:hover {
    .cell__sort {
      opacity: 1;
    }
  }
}


.sortable {
  cursor: pointer;

  &:hover {
    color: colors.$dark-gray-110;
  }
}
