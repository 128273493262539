@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  position: relative;
  width: 30%;
  height: 100%;
  border-radius: 0 8px 8px 0;
  &.info{
    justify-content: center;
    align-items: center;
    background: colors.$light-gray-20;
    overflow: hidden;
  }
  &.success{
    flex-direction: column;
    background: colors.$white;
  }
  @media(max-width: 1899px){
    width: 25%;
  }
  //@media(max-width: 1799px){
  //  min-width: 400px;
  //  max-width: 400px;
  //}
  @media(max-width: 1100px){
    min-width: 276px;
    max-width: 276px;
  }
}

.button {
  width: 100%;
  & > div {
    background: colors.$white;
    width: 100%;
  }
}

.ellipse115 {
  position: absolute;
  top: 28px;
  left: -179px;
  width: 476px;
  height: 476px;
  background: rgba(0, 34, 102, 0.12);
  border-radius: 50%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  width: 14rem;
  background-color: colors.$light-gray-10;
  border-radius: 2rem;
  &_success {
    background-color: colors.$green-10;
    color: colors.$green-70;
  }
}

.ellipse117 {
  position: absolute;
  bottom: -170px;
  left: -60px;
  width: 692px;
  height: 692px;
  background: #FFF;
  border-radius: 50%;
}

.ellipse116 {
  position: absolute;
  bottom: -566px;
  left: -250px;
  width: 791px;
  height: 791px;
  background: rgba(0, 34, 102, 0.12);
  border-radius: 50%;
  z-index: 1;
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.content {
  display: flex;
  padding: 0 217px;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  z-index: 2;
}

.image {
  width: 249px;
  height: 261px;
}

.title {
  @include text-style.heading-18;
}

.text {
  @include text-style.paragraph-14;
  color: colors.$dark-gray-130;
}

.ellipse120 {
  position: absolute;
  bottom: -147px;
  right: -185px;
  width: 353px;
  height: 353px;
  background: rgba(0, 34, 102, 0.12);
  border-radius: 50%;
  z-index: 1;
}

.footer{
  padding: 6rem 4rem;
  border-top: 1px solid colors.$light-gray-40;
  width: 100%;
  height: 14%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  @media(max-width: 1899px){
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    column-gap: 0;
    height: 19%;
    padding: 4rem;
    justify-content: center;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6rem;
  padding: 24px 64px;
  overflow-y: auto;
  height: 86%;
  flex: 1 1 auto;
  @media(max-width: 1899px) {
    height: 81%;
  }

  @media(max-width: 1599px){
    padding: 16px;
  }

  &-icon {
    border-radius: 60px;
    padding: 4rem;

    &.success {
      background: colors.$green-20;
      color: colors.$green-70;
    }

    &.loading {
      background: colors.$purple-20;
      color: colors.$purple-70;
    }
  }

  &-header {
    @include text-style.heading-24;
    max-width: 290px;
    text-align: center;
    @media(max-width: 1599px){
      @include text-style.heading-18;
    }
  }

  &-cloud {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    &-icon {
      width: 12rem;
      height: 12rem;
    }

    &-text {
      @include text-style.ui-14;
    }
  }

  &-text {
    text-align: center;
    @media(max-width: 1599px){
      text-align: left;
    }
  }
}
.buttonWrapper{
  & > div{
    @media(max-width: 1899px) {
      height: 32px;
    }
  }
}
