@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/colors.scss';

.container {
  border-radius: 8px;
}

.blockContent {
  margin-bottom: 4rem;
}

.appendButtonText {
  color: colors.$blue-70;
}

.blockContentLargeIndent {
  margin-bottom: 8rem;
}

.blockContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.items {
  display: grid;
  width: 100%;
  margin-bottom: 6rem;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.plusIcon {
  transform: rotate(45deg);
}

.addButton {
  width: fit-content;
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  margin-top: 6rem;
}

.blockTitle {
  @include text-style.heading-18;
  margin-bottom: 4rem !important;

  &.withBorder {
    border-bottom: 1px solid colors.$light-gray-30;
    padding-bottom: 4rem;
  }
}

.info {
  margin-bottom: 4rem;
}

.blockContainer > label {
  margin-right: 4rem;
}

.blockContainer > label:last-child {
  margin-right: 0;
}

.btns {
  display: flex;
}

.btns button:first-child {
  margin-right: 4rem;
}

.btn {
  width: 50%;
}
