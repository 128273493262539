@use '/src/assets/styles/variables/colors';

.progress {
  background: colors.$light-gray-20;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  &.lg {
    height: 1.5rem;
  }
  &.md {
    height: 1rem;
  }
}

.progress-value {
  background: colors.$green-70;
  border-radius: 100px;
  height: 100%;
  width: 0;
  transition: width 0.3s, background-color 0.3s;
  &.warning {
    background-color: colors.$red-60;
  }
}
