@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/shadows.scss';

.wrapper {
  align-items: center;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
}

.headerTitleIcon {
  position: relative;
}

.headerBackButton {
  margin-right: 2rem;
}

.title {
  @include text-style.heading-18;
  margin-right: 2rem;
}

.close {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
