* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background-image: url('/assets/images/background.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

#root {
  display: flex;
  flex-direction: column;
}

.mp-container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  @media (max-width: 1440px) {
    max-width: 920px;
  }
  @media (max-width: 1240px) {
    max-width: 810px;
  }
}
