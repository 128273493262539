@use '/src/assets/styles/variables/common';

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.pair {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.bar {
  overflow: hidden;
  border-radius: 2rem;
  height: 9.5rem;

  &_long {
    width: 100%;
  }

  &_short {
    width: 70%;
  }
}
