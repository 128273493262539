@use 'src/assets/styles/variables/colors.scss';

.button {
  display: block;
  margin-left: auto;
}

.buttonContainer {
  margin-bottom: 3rem;
}

.buttonText {
  color: colors.$blue-70;
}
