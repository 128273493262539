@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: grid;
  grid-template-columns: var(--tariff-table-template);
  align-items: flex-start;
  max-height: 100%;
  height: 100%;
  min-height: fit-content;
  background-color: colors.$white;

  &.odd {
    background-color: colors.$light-gray-10;
  }
}

.quotasWrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.info {
  display: flex;
  align-items: center;
  @include text-style.ui-14;
  padding: 8px;
  height: 100%;

  &.placeholder {
    color: colors.$gray-70;
  }

  &.price {
    display: flex;
    justify-content: flex-end;
  }

  &.quotas {
    grid-column: 3 / end;
  }
}

.quotaButton {
  & > div {
    padding: 0 8px;
  }
}

.quotas {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  &.button {
    flex-wrap: nowrap;
  }
}

.errorIcon {
  color: colors.$red-60;
}
