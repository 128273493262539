@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';

.loader {
  color: colors.$gray-60;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  min-width: 18rem;
}

.operationLoader {
  color: colors.$purple-80;
}

.time {
  color: colors.$purple-70;
  @include text-style.ui-12;
}
