$black: #000;
$white: #fff;

$dark-gray-150: #1a2024;
$dark-gray-140: #252c32;
$dark-gray-130: #303940;
$dark-gray-120: #3c464e;
$dark-gray-110: #48535b;

$gray-100: #5b6871;
$gray-90: #6e7c87;
$gray-80: #84919a;
$gray-70: #9aa6ac;
$gray-60: #b0babf;

$light-gray-50: #d5dadd;
$light-gray-40: #dde2e4;
$light-gray-30: #e5e9eb;
$light-gray-20: #eef0f2;
$light-gray-10: #f6f8f9;

$blue-100: #002266;
$blue-90: #023997;
$blue-80: #0452c8;
$blue-70: #0e73f6;
$blue-60: #4094f7;
$blue-50: #6bb3fa;
$blue-40: #9bcffd;
$blue-30: #d7edff;
$blue-20: #ebf7ff;
$blue-10: #f6fcff;

$red-100: #4d0004;
$red-90: #8d0104;
$red-80: #cc0905;
$red-70: #f2271c;
$red-60: #f76659;
$red-50: #fa8d7f;
$red-40: #fdaea1;
$red-30: #fed6cd;
$red-20: #ffefeb;
$red-10: #fffbfa;

$green-100: #003d06;
$green-90: #066f14;
$green-80: #119c2b;
$green-70: #22c348;
$green-60: #64d77f;
$green-50: #7de796;
$green-40: #9cf7b1;
$green-30: #bbfbd0;
$green-20: #ebfff1;
$green-10: #f5fff8;

$yellow-100: #4d2a00;
$yellow-90: #835101;
$yellow-80: #d29404;
$yellow-70: #f8c51b;
$yellow-60: #f8dd4e;
$yellow-50: #fbec7e;
$yellow-40: #fcf27d;
$yellow-30: #fef99f;
$yellow-20: #fffcc2;
$yellow-10: #fffeeb;

$purple-100: #350080;
$purple-90: #5606b2;
$purple-80: #7e10e5;
$purple-70: #a23fee;
$purple-60: #c479f3;
$purple-50: #d691f8;
$purple-40: #e8b1fc;
$purple-30: #f5d2fe;
$purple-20: #fcf0ff;
$purple-10: #fefaff;

$teal-100: #003833;
$teal-90: #046c54;
$teal-80: #0d9676;
$teal-70: #1ac19d;
$teal-60: #38d9b9;
$teal-50: #62e4d3;
$teal-40: #8ceee6;
$teal-30: #bbf6f4;
$teal-20: #e4fcfc;
$teal-10: #f1fdfd;

$coster: #38d9b9;
$clouder: #c479f3;
$architect: #3fc4ee;
