@use 'src/assets/styles/variables/common';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/shadows';

:global {
  .side-modal-content {
    height: 100%;
    margin: 0 0 0 auto !important;
    padding: 0;
  }

  .side-modal-overlay{
    outline: none;
  }
}

.overlay {
  justify-content: flex-end;
  align-items: flex-end;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  &Wrap {
    display: flex;
    flex-direction: column;
    background: colors.$white;
    box-shadow: shadows.$block;
    height: 100%;
    @include common.modal-enter-animation;
    padding: 0 !important;
    border-radius: 8px 0 0 0;
    width: 640px;
  }

  &Title {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 6rem 6rem 4rem 6rem;
    border-bottom: 1px solid colors.$light-gray-40;
  }

  &Content {
    overflow: hidden;
    height: 100%;

    &Inner {
      &.isSafari{
        &::-webkit-scrollbar{
          width: 0;
        }
      }

      scrollbar-width: none;
      overflow-y: auto;
      padding: 6rem;
      height: 100%;
    }
  }

  &Bottom {
    border-top: 1px solid colors.$light-gray-40;
    padding: 6rem;
  }
}

.closeIcon {
  @include common.control-icon;
}
