@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

$prefix: '.toggler';

#{$prefix} {
  display: flex;
  align-items: center;

  &-inner {
    padding: .5rem;
    cursor: pointer;
    background-color: colors.$gray-60;
    position: relative;
    border-radius: 3rem;

    &:hover {
      background-color: colors.$gray-70;
    }

    &:active {
      background-color: colors.$gray-80;
    }

    &:focus-visible {
      outline: 1rem solid colors.$blue-40;
    }
  }

  &_lg {
    gap: 3rem;

    #{$prefix}-inner {
      width: 8rem;
      height: 5rem;
    }

    #{$prefix}__circle {
      width: 4rem;
      height: 4rem;
    }
  }

  &_md {
    gap: 2.5rem;

    #{$prefix}-inner {
      width: 7rem;
      height: 4rem;
    }

    #{$prefix}__circle {
      width: 3rem;
      height: 3rem;
    }

  }

  &_sm {
    gap: 2rem;

    #{$prefix}-inner {
      width: 6rem;
      height: 3rem;
      gap: 2rem;
    }

    #{$prefix}__circle {
      width: 2rem;
      height: 2rem;
    }

    #{$prefix}__description {
      @include text-style.ui-12();
    }
  }

  &__circle {
    height: 100%;
    width: calc(50% - 0.5rem);
    background-color: colors.$white;
    border-radius: 50%;

    &_active {
      margin-left: auto;
    }
  }

  &_error {
    align-items: flex-start;
    #{$prefix}-inner {
      outline: .5rem solid colors.$red-60;
    }
  }

  &_disabled {
    #{$prefix}-inner {
      background-color: colors.$light-gray-40!important;
      pointer-events: none;
    }
  }


  &_active {
    #{$prefix}-inner {
      background-color: colors.$blue-60;

      &:hover {
        background-color: colors.$blue-70;
      }

      &:active {
        background-color: colors.$blue-80;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    @include text-style.paragraph-14();
    color: colors.$dark-gray-140;
    &__error {
      color: colors.$red-60;
      @include text-style.ui-12();
    }
  }
}

.storiesDescription {
  font-size: 4rem;
}

.toggler_disabled.toggler_active {
  #{$prefix}-inner {
    background-color: colors.$blue-60!important;
    opacity: 0.6;
    pointer-events: none;
  }
}
