.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.avatar {
  grid-column: 1 / 3;
}

.submit {
  grid-column: 2 / 3;
}
