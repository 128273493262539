@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';

.checkboxTree {
  @include text-style.paragraph-14;
  color: colors.$dark-gray-140;

  :global {
    .react-checkbox-tree {
      display: flex;
      flex-direction: row-reverse;
    }

    .react-checkbox-tree > ol {
      flex: 1 1 auto;
    }

    .react-checkbox-tree ol {
      margin: 0;
      padding-left: 0;
      list-style-type: none;
    }

    .react-checkbox-tree ol ol {
      padding-left: 8rem;
    }

    .react-checkbox-tree button {
      line-height: normal;
      color: inherit;
      padding: 0;
    }

    .react-checkbox-tree label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
    }

    .react-checkbox-tree:not(.rct-native-display) input {
      display: none;
    }

    .rct-text {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }

    .rct-node-leaf {
      margin-left: 6rem;
    }

    .rct-options {
      flex: 0 0 auto;
      margin-left: 0.5rem;
      text-align: right;
    }

    .rct-checkbox {
      &[aria-checked='true'] ~ .rct-title {
        @include text-style.ui-14-semi;
      }
    }

    .rct-collapse,
    .rct-checkbox {
      margin-right: 2rem;
      max-height: 6rem;
    }

    .rct-collapse {
      align-self: stretch;
      border: 0;
      background: none;
    }

    .rct-collapse.rct-collapse-btn {
      cursor: pointer;
    }

    .rct-node-icon {
      display: none;
    }

    .rct-disabled {
      & > .rct-text .rct-title {
        opacity: 0.5;
      }
      & > .rct-text .rct-checkbox {
        opacity: 0.3;
      }
    }

    .option,
    .optionChild.rct-disabled {
      & > .rct-text .rct-checkbox {
        display: none;
      }

      & > .rct-text .rct-node-icon {
        display: block;
      }
    }
  }
}

.icon {
  color: colors.$blue-70;
}

.caretRight {
  transform: rotate(-90deg);
}
.expand-btn {
  color: colors.$gray-70;
}
