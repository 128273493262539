@use '/src/assets/styles/variables/colors';

.toastWarningContainer {
  background: colors.$yellow-20;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
}

.colorIcon {
  color: colors.$yellow-70;
}

.btn {
  background-color: colors.$white;

  > div {
    margin: 0;
    border: 1px solid colors.$light-gray-40;
  }
}
