@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/reset.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.avatar {
  margin: 0 auto;
  width: 200px;
  height: 200px;
}

.infoBlock {
  display: flex;
  align-items: flex-start;
  gap: 4rem;

  & > div {
    width: 100%;
  }

  &.resetPass {
    align-items: flex-end;

    .btnResetPass {
      width: 100%;
    }
  }
}

.warning {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: colors.$light-gray-10;
  border-radius: 4px;
  padding: 2rem 4rem;

  .icon {
    color: colors.$gray-60;
  }

  .text {
    @include reset.indents;
    @include text-style.ui-14;
    color: colors.$dark-gray-150;
  }
}
