@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/text-style.scss';

:global {
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem 1.5rem 2rem;
    border-bottom: 1px solid colors.$light-gray-40;
    margin-bottom: 0;
  }

  .react-datepicker__day-name {
    @include text-style.ui-14;
    color: colors.$gray-70;
    width: fit-content;
    margin: 0 !important;
  }
}

.arrowLeft {
  color: colors.$gray-80;
}

.arrowRight {
  color: colors.$gray-80;
  transform: rotate(180deg);
}

.button {
  & > div {
    padding: 0;
  }
}

.yearsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 8px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.years {
  display: flex;
  align-items: center;
  &Item {
    @include text-style.ui-12;
    color: colors.$gray-70;
    margin-right: 16px;
    cursor: pointer;
    &.active {
      @include text-style.ui-14-semi;
      color: colors.$dark-gray-140;
    }
  }
}

.monthWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 7px 8px 7px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.monthTextContainer {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.monthText {
  @include text-style.ui-14-semi;
}
