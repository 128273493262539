@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

.wrapper {
  @include text-style.ui-14;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  border-color: colors.$light-gray-30;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  cursor: pointer;
  background: none;
  transition: border-color .3s, background-color .3s;

  &.filled_blue {
    border-color: colors.$light-gray-20;
    background-color: colors.$light-gray-20;
  }

  &.withIcon {
    padding: 0 2rem 0 1rem;
  }

  &:hover {
    &.filled_gray {
      border-color: colors.$light-gray-30;
      background-color: colors.$light-gray-20;
    }

    &.outlined_blue {
      border-color: colors.$blue-40;
      background-color: colors.$blue-10;
    }

    &.filled_blue{
      background-color: colors.$blue-20;
      border-color: colors.$blue-20;
    }
  }

  &:active {
    &.filled_gray {
      border-color: colors.$light-gray-30;
      background-color: colors.$light-gray-30;
    }

    &.outlined_blue {
      border-color: colors.$blue-60;
      background-color: colors.$blue-20;
      color: colors.$blue-60;
    }

    &.filled_blue{
      background-color: colors.$blue-30;
      border-color: colors.$blue-30;
      color: colors.$blue-60;
    }
  }

  &.isActive {
    &.filled_gray {
      border-color: colors.$light-gray-30;
      background-color: colors.$light-gray-30;
    }

    &.outlined_blue {
      border-color: colors.$blue-60;
      background-color: colors.$blue-20;
      color: colors.$blue-60;
    }

    &.filled_blue{
      background-color: colors.$blue-30;
      color: colors.$blue-60;
    }
  }
}
