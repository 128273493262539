@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  width: 100%;
}

.tariffBlock {
  display: flex;
  flex-direction: column;
  max-width: 286px;
  gap: 16px;
  width: 100%;
  padding: 10px 8px;
  border-bottom: 1px solid colors.$light-gray-30;
  border-right: 1px solid colors.$light-gray-30;
}

.resources {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 6px;
}

.icon {
  color: colors.$blue-60;
}
