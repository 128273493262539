@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.connectionBlock {
  padding-bottom: 6rem;
  color: colors.$gray-100;
  max-width: 150rem;
  &:last-child {
    padding-bottom: 0;
  }
}
