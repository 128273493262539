@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  grid-gap: 16rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: colors.$white;
  position: fixed;
  padding: 4rem;
  @media (max-width: 700px) {
    flex-direction: column;
    grid-gap: 8rem;
  }
}

.textsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 450px;
}

.title {
  @include text-style.heading-36;
  color: colors.$dark-gray-140;
}

.icon {
  width: fit-content;
  height: fit-content;
}

.comment {
  @include text-style.paragraph-14;
  color: colors.$gray-100;
  margin-top: 4rem;
  margin-bottom: 8rem;
  @media (max-width: 700px) {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
}

.button {
  max-width: fit-content;
}
