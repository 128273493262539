@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  --tariff-table-template: 250px 250px 101px 142px minmax(200px, 1fr);
  --tariff-table-header-template: 286px var(--tariff-table-template);

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 20px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.clouds {
  display: flex;
  width: 100%;
  min-height: 20px;
  gap: 8px;
  align-items: center;
}

.cloudsLoading {
  width: 100%;
  min-height: 26px;

  & > div {
    min-height: 26px;
  }
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  border-bottom: 1px solid colors.$light-gray-40;
}

.cloudChip {
  display: flex;
  align-items: center;
  gap: 4px;
}

.editText {
  @include text-style.ui-12;
  color: colors.$gray-80;
  margin-left: 6px;
}

.icon {
  display: block;
  min-width: 6rem;
  width: 6rem;
  height: 6rem;
}

.tariffs {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.tariffsWrapper {
  height: 100%;
  overflow-y: auto;
}
