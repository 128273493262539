@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/shadows.scss';
@use 'src/assets/styles/variables/text-style.scss';

.badge {
  @include text-style.ui-12;
  padding: 0 1rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
}

.container_Active {
  background-color: colors.$green-20;
  color: colors.$green-70;
}

.container_Stopped {
  background-color: colors.$blue-20;
  color: colors.$blue-80;
}

.container_UpdateError {
  background-color: colors.$yellow-20;
  color: colors.$yellow-80;
}

.container_InvalidCredentials {
  background-color: colors.$red-20;
  color: colors.$red-80;
}

.container_WaitingReport{
  background-color: colors.$purple-20;
  color: colors.$purple-80;
}

.iconContainer {
  margin-right: 1.5rem;
}
