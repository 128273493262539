@mixin rtl($counts) {
  @for $i from 0 to $counts {
    .fade-in-#{$i + 1} {
      opacity: 0;
      animation: fade-in 300ms forwards #{($i + 1) * 100}ms;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include rtl(8);
