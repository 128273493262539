@use '/src/assets/styles/variables/common';

.wrapper {
  overflow: hidden;
  min-width: 0;
}

.content {
  @include common.text-no-wrap;
}

.isHidden {
  visibility: hidden !important;
}
