@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.ttf')
      format('truetype')
    ///* Safari, Android, iOS */ url('/src/assets/fonts/RobotoMono/roboto-mono-v13-latin_cyrillic-regular.svg#RobotoMono')
    //  format('svg'); /* Legacy iOS */
}
