@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.status{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.vCloud{
    justify-content: flex-start;
    align-items: flex-start;
  }
  &-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6rem;
  }
  &-circle{
    border-radius: 60px;
    padding: 4rem;
    &.success{
      background: colors.$green-20;
      color: colors.$green-70;
    }
    &.loading{
      background: colors.$purple-20;
      color: colors.$purple-70;
    }
  }
  &-block{
    border-radius: 2rem;
    padding: 4rem;
    &.error{
      background: colors.$red-20;
      color: colors.$red-60;
    }
  }
  &-header{
    @include text-style.heading-24;
    max-width: 290px;
    text-align: center;
  }
  &-cloud{
    display: flex;
    align-items: center;
    column-gap: 2rem;
    &-icon{
      width: 12rem;
      height: 12rem;
    }
    &-text{
      @include text-style.ui-14;
    }
  }
  &-description{
    max-width: 500px;
    text-align: center;
    @include text-style.paragraph-14;
  }
  &-buttons{
    display: grid;
    column-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    &.one{
      display: flex;
    }
  }
}
