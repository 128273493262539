@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/shadows.scss';

.wrapper {
  padding: 4rem 6rem;
  border: 1px solid colors.$light-gray-30;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: colors.$gray-100;
  margin-bottom: 2rem;
}

.buttonContent {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0 2rem;

  span {
    color: colors.$dark-gray-140;
  }
}

.apiUrl {
  margin: 0;
  span {
    color: colors.$dark-gray-140;
    margin-left: 2rem;
  }
}

.clientId {
  margin: 0;
  span {
    color: colors.$dark-gray-140;
    margin-left: 2rem;
    @include text-style.ui-14-semi;
  }
}

.domainWrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  p {
    margin: 0;
  }
}

.domains {
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.domain {
  background: colors.$light-gray-10;
  border-radius: 1rem;
  @include text-style.ui-14;
  padding: 1rem 2rem;
}
