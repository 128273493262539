@use '/src/assets/styles/variables/colors';

@keyframes color {
  0% {
    background-color: colors.$light-gray-20;
  }

  50% {
    background-color: colors.$light-gray-30;
  }

  100% {
    background-color: colors.$light-gray-20;
  }
}

.colorPulsator {
  width: 100%;
  height: 100%;
  background-color: colors.$light-gray-20;
  animation-name: color;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
