@use 'src/assets/styles/variables/colors';

.profileItemContent {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2.5rem;
  grid-template-columns: 1fr 0.8fr 1fr 24px;
  transition: background-color .3s;

  padding: 4rem 2.5rem 4rem 4rem;
  border-bottom: 1px solid colors.$light-gray-30;

  &.isFirst {
    border-top: 1px solid colors.$light-gray-30;
  }

  &:hover {
    background-color: colors.$blue-10;
  }
}

.price {
  padding: 0 1.5rem;
}

.profileItemHeaderText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  white-space: nowrap;

  color: colors.$gray-70;
}

.profileItemContainer {
  display: grid;
  grid-row-gap: 3rem;
  width: 100%;
}

.profileItemHeader {
  display: flex;
  align-items: center;
}

.deleteIcon {
  color: colors.$gray-60;
  transition: color .3s;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  position: relative;
  top: 5px;

  &.isFirst {
    top: 32px;
  }

  &:hover {
    color: colors.$red-70;
  }
}

.divider {
  width: 100%;
  height: 1px;
  min-height: 1px;
  margin: 0 3rem;
  background: colors.$light-gray-30;
}
