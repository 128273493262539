@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/shadows';
@use '/src/assets/styles/variables/common';
@use '/src/assets/styles/variables/adaptive-resolutions';

.select {
  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 6rem;
    transition: all 100ms;

    background-color: colors.$white;
    border-color: colors.$light-gray-40;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;

    &:hover {
      background-color: colors.$light-gray-10;
      border-color: colors.$light-gray-40;
    }

    &.menuIsOpen {
      background-color: colors.$light-gray-20;
      border-color: colors.$light-gray-40;
    }

    &.isError {
      border-color: colors.$red-60;
    }

    &.lg {
      height: 10rem;
      padding: calc(2rem - 1px) calc(2rem - 1px) calc(2rem - 1px) calc(4rem - 1px);
      &.adaptiveOn{
        @include adaptive-resolutions.desktop-max-width {
          height: 8rem;
          padding: 1rem 3rem;
        }
        @include adaptive-resolutions.desktop-min-width {
          height: 7rem;
          padding: 1.5rem 3rem;
        }
        @include adaptive-resolutions.tablet-max-width {
          height: 7rem;
        }
      }
    }
    &.md {
      height: 8rem;
      padding: calc(1rem - 1px) calc(1rem - 1px) calc(1rem - 1px) calc(3rem - 1px);
      &.adaptiveOn{
        @include adaptive-resolutions.desktop-min-width {
          height: 7rem;
          padding: 1.5rem 3rem;
        }
        @include adaptive-resolutions.tablet-max-width {
          height: 7rem;
        }
      }
    }
    &.sm {
      height: 6rem;
      padding: 0 0 0 calc(2rem - 1px);
    }

    &.isDisabled {
      background: colors.$light-gray-10;
      border-color: colors.$light-gray-20;
    }

    &.withoutBorder {
      cursor: pointer;
      border-color: transparent;
    }

    overflow: hidden;
  }

  .valueContainer {
    @include text-style.ui-14;
    padding: 0;
    height: 6rem;
    flex-wrap: nowrap;

    color: colors.$dark-gray-140;
    &.adaptiveOn{
      @include adaptive-resolutions.desktop-min-width {
        height: 4rem;
        @include text-style.ui-12;
      }
    }

    &.sm {
      height: calc(6rem - 2px);
    }

    &.isDisabled {
      opacity: 0.9;
    }
  }

  .noOptionsText {
    padding: 0;
    min-width: 15rem;
    transform: translate(-2rem);
  }

  .icon {
    color: colors.$gray-90;
    margin-right: 1rem;
    flex-shrink: 0;

    &.loader {
      color: colors.$gray-60;
    }

    &.isDisabled {
      opacity: 0.4;
    }
  }
}

.menuControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include text-style.ui-12;
  margin: 3rem 5rem;
  order: 2;
  &.withSubmit {
    order: unset;
  }

  .controlItem {
    cursor: pointer;
    color: colors.$blue-70;
    &:not(:last-child) {
      margin-right: 4rem;
    }

    &.selected {
      color: colors.$blue-90;
    }
  }
}

.menuControlsContainer {
  display: flex;
  flex-direction: column;

  & div {
    &:nth-child(2) {
      order: 3;
    }
  }

  &.withSubmit {
    & div {
      &:nth-child(2) {
        order: unset;
      }
      &:nth-child(1) {
        order: -1;
      }
      &:nth-child(3) {
        order: -1;
      }
    }
  }
}

.optionContainer {
  .option {
    margin: 0.5rem 0;
    padding: 1rem;
    cursor: pointer;
    color: inherit;
    border-radius: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    transition: background-color 0.2s;

    &:active,
    &.isFocused {
      background-color: colors.$light-gray-10;
    }

    &.isSelected {
      background-color: colors.$light-gray-30;
    }
  }

  .optionWithIcon {
    display: flex;
    align-items: center;
    font-size: 3.5rem;
    .icon {
      width: 6rem;
      height: 6rem;
      margin-right: 2rem;
    }
  }
}

.caption {
  @include text-style.ui-14;
  color: colors.$dark-gray-140;
  margin-bottom: 1rem;

  &.isDisabled {
    opacity: 0.4;
  }
}

.captionTooltip {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
}

.error {
  @include common.filed-error-message;
  margin-top: 1rem;
}

.description {
  @include text-style.ui-14;
  color: colors.$gray-70;

  &.top {
    margin-bottom: 1.5rem;
  }
}

.hover {
  position: absolute;
  right: 0;
  width: 6rem;
  height: 100%;
  background-color: colors.$light-gray-10;
  border-left: 1px solid colors.$light-gray-40;
}

.hint {
  cursor: help;
  color: colors.$gray-60 !important;
}

.search {
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;

  &.withoutBottomPadding {
    padding-bottom: 0;
  }
}

.containerOptions {
  display: flex;
  justify-content: space-between;
}

.typeForm {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
}

.submitContainer {
  display: flex;
  padding: 2rem 2rem 0;
  border-top: 1px solid colors.$light-gray-30;
  justify-content: flex-end;
}

:export {
  gray10: colors.$light-gray-10;
  gray20: colors.$light-gray-20;
  gray70: colors.$gray-70;
  shadow: shadows.$block;
}

.textShorterConainer {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  width: 100%;
}
