@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/common';
@use '/src/assets/styles/variables/text-style';

.multiValue {
  display: flex;
  align-items: center;
  max-width: calc(100% - 2rem);

  .text {
    margin-right: 1rem;
  }

  .counter {
    pointer-events: none;
    color: colors.$blue-70;
    white-space: nowrap;

    &.clickable {
      pointer-events: auto;
      cursor: pointer;
    }
  }
}

.toolTipMultiValues {
  display: flex;
  flex-direction: column;
  height: max-content;
}

.toolTipMultiValuesText {
  @include text-style.ui-10;
}
