@use '/src/assets/styles/variables/common';

:global {
  .table-modal-content {
    margin-right: 6rem !important;
    margin-top: 6rem !important;
  }
}

.modal {
  @include common.modal-enter-animation;
  height: calc(100vh - 12rem);
  display: flex;
  flex-direction: column;
}

.modalBody {
  flex-grow: 1;
}

.close {
  @include common.control-icon;
}

.leftButton {
  margin-right: 4rem;
}
