@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/common';
@use '/src/assets/styles/variables/colors';

.container {
  display: flex;
  border-color: inherit;
  gap: 3.5rem;
}

.units {
  display: flex;
  align-items: center;
  color: colors.$gray-90;
}

.controls {
  display: flex;
  flex-direction: column;
  border-color: inherit;
  &.lg {
    width: 8rem;
  }
  &.md {
    width: 7rem;
  }
}

.button {
  @include reset.button;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  @include common.control-icon;
  border-top: 1px solid colors.$light-gray-40;
  border-left: 1px solid colors.$light-gray-40;
  border-color: inherit;
}

.icon {
  color: colors.$gray-90;
  &.rotate {
    transform: rotate(180deg);
  }
}
