@use '/src/assets/styles/variables/common';

.content {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  p {
    margin: 0;
    white-space: pre;
  }
}

