@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/reset';

.container {
  @include reset.button;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.2s;
  position: relative;
  text-decoration: none;
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
  &:focus-visible {
    outline: 3px solid colors.$blue-40;
  }
  &.sm {
    border-radius: 4px;
  }
}

.full {
  width: 100%;
}

.wrapper {
  border-radius: inherit;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  color: colors.$dark-gray-140;
}

.filled {
  color: colors.$light-gray-10;
  background-color: colors.$blue-60;
  .icon {
    color: colors.$white;
  }
  &.isLoading {
    background-color: colors.$blue-20;
  }
  &:not(.isLoading) {
    &:hover {
      background-color: colors.$blue-70;
    }

    &:active,
    &.active {
      background-color: colors.$blue-80;
    }
  }

  .count {
    color: colors.$blue-60;
    background-color: colors.$blue-30;
  }
}

.outline {
  background-color: transparent;
  border-color: colors.$light-gray-40;
  &.isLoading {
    border-color: colors.$light-gray-20;
  }
}

.text,
.outline {
  &:not(.isLoading) {
    &:hover {
      background-color: colors.$light-gray-10;

      .negativeIcon {
        color: colors.$red-60;
      }

      .icon {
        color: colors.$gray-90;
      }
    }

    .negativeIcon {
      color: colors.$red-60;
    }

    .icon {
      color: colors.$gray-80;
    }

    &:active,
    &.active {
      background-color: colors.$light-gray-30;

      .negativeIcon {
        color: colors.$red-60;
      }

      .icon {
        color: colors.$gray-100;
      }
    }
  }
}

.count {
  @include text-style.ui-12;
  text-align: center;
  position: relative;
  color: colors.$white;
  margin-left: 2px;
  padding: 0 1rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: colors.$blue-60;
  margin-bottom: auto;
}

.leftImage {
  margin-right: 2rem;
}

.rightImage {
  margin-left: 2rem;
}

.lg {
  padding: calc(2rem - 1px);
}

.md {
  padding: calc(1rem - 1px);
}

.sm {
  margin: -1px;

  .leftImage {
    &.onlyImg {
      margin: 0;
    }
    margin-right: 1rem;
  }

  .rightImage {
    &.onlyImg {
      margin: 0;
    }
    margin-left: 1rem;
  }

  .count {
    margin-left: 1px;
  }
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: flex;
}

.loadingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  .filled.sm &,
  .iconOnly.lg & {
    padding: 0 2rem;
  }
  .iconOnly.md & {
    padding: 0 1.5rem;
  }
  .iconOnly.sm & {
    padding: 0 1rem;
  }
}

.progressBar {
  flex-grow: 1;
  background-color: colors.$blue-30;
  border-radius: 2px;
  overflow: hidden;
  height: 2px;
  z-index: 2;
  .track {
    height: 100%;
    width: 10%;
    background-color: colors.$blue-60;
    transition: width 0.3s;
  }
}

.spinner {
  font-size: 0;
  line-height: 0;
  position: absolute;
  .icon {
    color: colors.$blue-60;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  .iconOnly & {
    padding: 0;
  }
  .isLoading & {
    visibility: hidden;
  }
}

.caption {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include text-style.ui-14;

  .filled & {
    @include text-style.ui-14-semi;
  }
}

.negative {
  &.container:focus-visible {
    outline-color: colors.$red-40;
  }

  .wrapper {
    color: colors.$red-70;
  }

  .filled {
    color: colors.$light-gray-10;
    background-color: colors.$red-60;
    &.isLoading {
      background-color: colors.$red-20;
    }
    &:not(.isLoading) {
      &:hover {
        background-color: colors.$red-70;
      }

      &:active,
      &.active {
        background-color: colors.$red-80;
      }
    }
  }

  .progressBar {
    background-color: colors.$red-30;
    .track {
      background-color: colors.$red-60;
    }
  }

  .spinner .icon {
    color: colors.$red-60;
  }
}

.onlyImg {
  margin: 0;
}
