.container {
  display: flex;
  align-items: center;
  gap: 1rem;

  &.canSort {
    cursor: pointer;
  }
}

.sortIndicator {
  flex-shrink: 0;
}