@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  gap: 4px;
  padding: 0 8px;
  align-items: center;
  border-radius: 6px;
  height: fit-content;

  &.FIXED {
    border: 1px solid colors.$blue-50;
    background: colors.$blue-10;
  }

  &.PAYG {
    border: 1px solid colors.$purple-50;
    background: colors.$purple-10;
  }
}

.infoText {
  @include text-style.ui-12;
  color: colors.$gray-100;
  white-space: nowrap;
}

.valueText {
  @include text-style.ui-12-semi;
  color: colors.$gray-100;
}

.priceWrapper {
  display: flex;
  gap: 3px;
  align-items: center;
}

.price {
  @include text-style.table-number-14;
  font-weight: 600;
}
