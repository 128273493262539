@use '/src/assets/styles/variables/colors';

$default-gradient: rgba(255, 255, 255, 0.9);
$special-red: #f44336;

.toastErrorContainer {
  background: linear-gradient(0deg, $default-gradient, $default-gradient), $special-red;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
}

.colorIcon {
  color: colors.$red-70;
}

.btn {
  background-color: colors.$white;

  > div {
    margin: 0;
    border: 1px solid colors.$light-gray-40;
  }
}
