@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';

.title {
  @include text-style.heading-18;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dateInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.textsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 24px;
  border-top: 1px solid colors.$light-gray-40;
}

.icon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 50%;
  color: colors.$yellow-70;
  background-color: colors.$yellow-30;
}

.calendarIcon {
  width: 20px;
  height: 20px;
  color: colors.$gray-80;
}

.date {
  @include text-style.ui-14-semi;
}

.close {
  color: colors.$gray-60;
  transition: opacity .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 24px 8px 24px;
}
