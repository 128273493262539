@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';

.creation {
  display: flex;
  flex-direction: column;

  &Title {
    @include text-style.ui-12-semi;
    color: colors.$gray-60;
    margin-bottom: 1rem;
  }
}
