@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/font-family';

.toastText {
  @include reset.text;
  @include reset.indents;
  @include text-style.paragraph-14;
  font-family: font-family.$primary;
}
