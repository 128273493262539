@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.modal {
  overflow: visible;
}

.container {
}

.blockInput {
  z-index: 1000;
  padding-bottom: 6rem;
}

.info {
  margin-bottom: 6rem;
}

.infoIcon {
  color: colors.$gray-60;
  width: 5rem;
  height: 5rem;
}

.infoText {
  max-width: 118rem;
  @include text-style.ui-14;
}

.btns {
  display: flex;
}

.btn {
  width: 50%;
}

.btn:first-child {
  margin-right: 4rem;
}
