@use 'src/assets/styles/variables/layout.scss';
@use 'src/assets/styles/variables/colors';

$header-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tech-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: colors.$yellow-30;
}

.header {
  background-color: colors.$white;
  height: layout.$header-height;
  box-shadow: $header-shadow;
  padding: 2rem 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLeft {
  margin-right: 6rem;
}

.nav {
  flex-grow: 1;
}

.headerRight {
  display: flex;
  align-items: center;
}

.logoBlock {
  display: flex;
  align-items: center;
  max-height: 5rem;
  color: colors.$black;
  gap: 2rem;
}

.logoIcon {
  flex-shrink: 0;
  width: 4.5rem;
  height: 5rem;
}

.logoName {
  flex-shrink: 0;
  width: 34rem;
  height: 4.25rem;
}

.divider {
  width: 1px;
  height: 6rem;
  background-color: colors.$light-gray-40;
  margin: 0 2rem;
}

.icons {
  color: colors.$gray-70;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 3rem;

  & > div:not(:last-of-type) {
    margin-right: 2rem;
  }

  .iconButton {
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: colors.$gray-90;
    }
    &:active,
    &.isOpened {
      color: colors.$blue-60;
    }
  }
}

.iconButton {
  transition: color 0.2s;
  cursor: pointer;
  color: colors.$gray-70;
  &:hover {
    color: colors.$gray-90;
  }
  &:active,
  &.isOpened {
    color: colors.$blue-60;
  }
}
