@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/shadows';

.popup {
  background-color: colors.$white;
  box-shadow: shadows.$block;
  border-radius: 8px;

  &.sm {
    padding: 2rem 0;
  }
  &.md {
    padding: 3rem;
  }
  &.lg {
    padding: 4rem;
  }
  &.xl {
    padding: 6rem;
  }
}
