.buttonsContainer {
  display: flex;
  gap: 4rem;
}

.headerContainer {
  margin-bottom: 4rem;
}

.demoBarContainer {
  margin-bottom: 8rem;
}
