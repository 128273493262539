@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/reset';
@use '/src/assets/styles/variables/common';

.body {
  display: block;
  @include text-style.ui-14;
  color: colors.$dark-gray-140;
  width: 100%;
  border-radius: 6px;

  .icon {
    color: colors.$gray-60;
    flex-shrink: 0;
  }

  .hint {
    color: colors.$gray-60 !important;
    cursor: help;
  }

  .caption {
    display: flex;
    align-items: center;
    color: colors.$dark-gray-140;
    gap: 1rem;
  }

  .wrapper {
    display: flex;
    width: 100%;

    border: 1px solid colors.$light-gray-40;
    background-color: colors.$white;
    border-radius: inherit;
    transition: border 0.2s;

    &:hover {
      border-color: colors.$gray-60;
    }
    &.isError {
      border-color: colors.$red-60;
    }
    &.isInFocus {
      border-color: colors.$gray-100;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &:not(:first-child) {
      margin-top: 1rem;
    }

    .controller {
      display: flex;
      flex-grow: 1;
      align-items: center;

      &.size_sm {
        padding: 0 3px;
      }

      &.size_md {
        padding: calc(1rem - 1px) 1rem;
      }
      &.size_lg {
        padding: calc(2rem - 1px) 2rem;
      }
    }

    .field {
      @include reset.input;

      flex-grow: 1;
      padding: 0 2rem;
      @include common.text-no-wrap;
      width: 100%;

      &::placeholder {
        color: colors.$gray-70;
      }

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    .clear {
      @include common.control-icon;
    }

    .suffix,
    .prefix {
      background-color: colors.$light-gray-10;
      height: 100%;
      color: colors.$gray-90;
      border-radius: 6px;
      @include text-style.ui-14;

      &.size_md {
        padding: calc(1rem - 1px) 3rem;
      }
      &.size_lg {
        padding: calc(2rem - 1px) 4rem;
      }
    }
  }

  .error {
    @include common.filed-error-message;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  .description {
    @include text-style.ui-12;
    color: colors.$gray-70;
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  &.disabled {
    .wrapper {
      background-color: colors.$light-gray-10;
      border-color: colors.$light-gray-20;
      color: colors.$gray-60;
    }
    .caption {
      opacity: 0.4;
    }
  }
}

.extra {
  display: flex;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  margin: -1px 0;
  overflow: hidden;
  border-color: inherit;
  flex-shrink: 0;
}
