@use 'src/assets/styles/variables/colors.scss';
@use 'src/assets/styles/variables/text-style.scss';

.search {
  padding: 0 20px !important;
}
.inner-element {
  padding: 0 2rem;
}
.select-all {
  border-bottom: 1px solid colors.$light-gray-30;
  padding: 0 20px 3rem 20px;
  display: flex;
  &_left {
    justify-content: flex-start;
  }
  &_right {
    justify-content: flex-end;
  }
}
