@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/text-style';

.title {
  @include text-style.heading-18;
  margin-right: 2rem;
}

.close {
  color: colors.$gray-60;
  transition: opacity .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
