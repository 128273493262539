@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';
@use 'src/assets/styles/variables/shadows.scss';

.close {
  color: colors.$gray-60;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include text-style.heading-18;
  margin-right: 2rem;
}

.form {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
  border: 1px solid colors.$light-gray-30;
  padding: 4rem 6rem;
  border-radius: 2rem;
}

.rowContainer {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.full {
  grid-template-columns: 1fr;
}

.fieldArrayBlock {
  background: colors.$light-gray-10;
  border-radius: 4px;
  width: 100%;
  padding: 2rem 4rem;

  &Title {
    @include text-style.paragraph-14;
    margin: 0;
  }
}

.formWrapper {
  padding: 6rem;
}

.buttonsWrapper {
  display: flex;
  gap: 2rem;
  margin-left: auto;
}

