@use 'src/assets/styles/variables/colors';

.button {
  width: fit-content;

  & > div {
    color: colors.$blue-70;
    padding: 0;
  }
}

.icon {
  color: colors.$blue-70;
  margin-right: -3px;
}
