@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid colors.$light-gray-30;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 16px;

  &.isOrganization {
    padding: 16px;
    border-bottom: 1px solid colors.$light-gray-30;
  }
}

.tariffBlock {
  display: flex;
  flex-direction: column;
  max-width: 286px;
  gap: 16px;
  width: 100%;
  border-right: 1px solid colors.$light-gray-30;

  &.isOrganization {
    gap: 0;
  }
}

.disclaimer {
  border-radius: 0;
  border: none !important;
  height: 100%;
  padding: 2rem 3rem;
}

.resources {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 6px;
}

.disclaimer-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.badge {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  border-radius: 12px;
  width: fit-content;
  background: colors.$light-gray-20;
}

.icon {
  color: colors.$gray-70;

  &.isOrganization {
    color: colors.$blue-60;
  }
}
