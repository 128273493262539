@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style';
@use 'src/assets/styles/variables/common';

.wrap {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  grid-gap: 6rem;
  animation: fade-in 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.text {
  @include text-style.paragraph-16-semi();
  color: colors.$gray-100;
}

.spin {
  @include common.spin-animation();
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
