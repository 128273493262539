@use '/src/assets/styles/variables/text-style';
@use '/src/assets/styles/variables/colors';
@use '/src/assets/styles/variables/reset';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.iconContainer {
  &:hover~.tooltip{
    display: block;
  }

  &:hover::before{
    display: block
  }
}

.tooltip {
  display: none;
  height: 5rem;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2rem 1rem;
  color: colors.$white;
  background-color: colors.$dark-gray-130;
  transition: 0.3s ease-in-out;
  font-size: 3rem;
  line-height: 3rem;
  border-radius: 4px;
  position: absolute;
  top: -35px;
  white-space: nowrap;
}

.iconContainer::before {
  display: none;
  content: '';
  border-top:  8px solid colors.$dark-gray-130;
  border-right: 8px solid transparent;
  border-left:  8px solid transparent;
  position: absolute;
  top: -15px;
  left: 0;
}
