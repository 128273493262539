@use 'src/assets/styles/variables/colors';
@use 'src/assets/styles/variables/text-style.scss';

.text {
  @include text-style.paragraph-14;
  color: colors.$gray-100;
  max-width: 769px;

  a {
    color: colors.$blue-70;
  }
}
